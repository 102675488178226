import React, { ReactNode, useContext } from "react";
import { exportCHResultsToExcel } from '../../../helpers/commHistoryExportService'
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../appContext"

type ExcelExportProps = {
  data: Array<any>
  selectedMessageTypes: string[]
  fromDate?: Date | string
  toDate?: Date | string
  vin?: string
  children?: ReactNode
  disabled: boolean
}

const CommHistoryExcelExportButton = ({ data, selectedMessageTypes, children, disabled, fromDate, toDate, vin }: ExcelExportProps) => {

  const { t } = useTranslation();
  const { role } = useContext(UserContext);

  return <button
    type="button"
    className='btn-export sdds-btn sdds-btn-primary sdds-btn-md'
    onClick={() => exportCHResultsToExcel(data, selectedMessageTypes, t, role, fromDate, toDate, vin)}
    disabled={disabled}>
    {children && children}
  </button>;
}

export default CommHistoryExcelExportButton;
