import React, { ReactNode } from "react";
import { exportMPLogsToExcel } from '../../helpers/MPLogSearchService'
import { useTranslation } from "react-i18next";

type MPLogExportProps = {
  data: Array<MPLogRecordType>
  vin:string
  children?: ReactNode
  disabled: boolean
  slot?:string
}

const MPLogExportButton = ({ slot, data, children, disabled, vin }: MPLogExportProps) => {

  const { t } = useTranslation();
 
  return <button type="button" slot={slot} className='btn-export sdds-btn sdds-btn-primary sdds-btn-sm' onClick={() => exportMPLogsToExcel(data,t, vin)} 
          disabled={disabled}>{children && children}</button>;
}

export default MPLogExportButton;
