
import { RtcSvg } from '@cd/sdds-common-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
interface RtcDetailsPropType {
    rtcDataList?: RtcDetails
}

const RtcDetails = ({ rtcDataList }: RtcDetailsPropType) => {

    const { t } = useTranslation();
    return <>
        <div className='details'>
            <h2>
                <RtcSvg /> {t("TS_core:RtcDetails")}
            </h2>
            {!rtcDataList && <ul>
                <li><span className="noData">{t("TS_core:NoDataFound")} </span></li>
            </ul>}
            {rtcDataList && <ul>
                <li><span>{t("TS_core:CommunicatorModel")}</span> {rtcDataList?.model}</li>
                <li><span>{t("TS_core:Version")}</span> {rtcDataList?.version}</li>
                <li><span>{t("TS_core:MobileId")}</span>{rtcDataList?.mobileId}</li>
                <li><span>{t("TS_core:Msisdn")}</span> {rtcDataList?.msisdn}</li>
                <li><span>{t("TS_core:Imsi")}</span> {rtcDataList?.imsi}</li>
                <li><span>{t("TS_core:Assy")}</span> {rtcDataList?.assy}</li>
                <li><span>{t("TS_core:SimStatus")}</span> {rtcDataList?.simStatus}</li>
            </ul>}
        </div>
    </>
}

export default RtcDetails;