
import React, { useEffect, useMemo, useState } from 'react';
import RtcDetails from '../../components/VehicleRtcSearch/Rtc/rtcDetails';
import RtcHistory from '../../components/VehicleRtcSearch/Rtc/rtcHistory';
import _, { sortBy } from 'lodash';
import { sortRtcHistoryeDataList } from '../../redux/store/vehicleRtcHistory';
import { useAppDispatch } from '@cd/sdds-common-components-react/lib/esm/store';

interface RtcWarpperPropTyps {
  rtcDetails: RtcDetails
  rtcHistoryDataList: Array<RtcHistory>
  isRtcHistoryLoading: boolean
  onHandleRedirect: (searchInput: string) => void
}


const RtcWarpper = ({ rtcDetails, rtcHistoryDataList, isRtcHistoryLoading, onHandleRedirect}: RtcWarpperPropTyps) => {

  const dispatch = useAppDispatch();
  const [rtcHistoryList, setRtcHistoryList] = useState<Array<RtcHistory>>(rtcHistoryDataList);
  const [rtcDetail, setRtcDetail] = useState<RtcDetails>(rtcDetails);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);

  useEffect(() => {
    setRtcHistoryList(rtcHistoryDataList);
    setCurrentPageIndex(1);
  }, [rtcHistoryDataList]);

  useEffect(() => {
    setRtcDetail(rtcDetails);
  }, [rtcDetails]);

  const handleCustomerSearch = (searchString: string) => {
    if (searchString.length > 0) {
      if (rtcHistoryDataList && rtcHistoryDataList.length > 0) {
        const splitedString = searchString.split(",");
        let filteredDataArray;
        if (splitedString && splitedString.length > 0) {
          filteredDataArray = splitedString.map((searchstr: string) => {
            searchstr = searchstr.trim();
            if (searchstr === "") return [];
            return rtcHistoryDataList.filter(a => _.values(a).some((b: any) => {
              if (typeof b === 'string') {
                return b?.toLocaleLowerCase().includes(searchstr.toLocaleLowerCase())
              } else if (typeof b === 'object') {
                return JSON.stringify(b).toLocaleLowerCase().includes(searchstr.toLocaleLowerCase())
              } else {
                return b?.toString().toLocaleLowerCase() === searchstr.toLocaleLowerCase()
              }
            }
            ));
          })
        }
        setRtcHistoryList(_.flatten(filteredDataArray) as Array<RtcHistory>);
        return false;
      }
    } else {
      setRtcHistoryList(rtcHistoryDataList);
    }
  }

  const onSortData = (columnId: string, sortDirection: string) => {
    if (rtcHistoryDataList && rtcHistoryDataList.length > 0) {
      let sortedResults = sortBy(rtcHistoryDataList, columnId);
      if (sortDirection === 'desc') {
        sortedResults = _.reverse(sortedResults);
      }
      dispatch(sortRtcHistoryeDataList(sortedResults as Array<RtcHistory>));
    }
  }

  const handlePaginationData = useMemo(() => {
    const firstPageIndex = (currentPageIndex - 1) * 20;
    const lastPageIndex = firstPageIndex + 20;
    return rtcHistoryList.slice(firstPageIndex, lastPageIndex);
  }, [currentPageIndex, rtcHistoryList]);

  return <>
    <RtcDetails
      rtcDataList={rtcDetail} />
    <RtcHistory
      rtcHistoryDataList={handlePaginationData}
      isLoading={isRtcHistoryLoading}
      currentPageIndex={currentPageIndex}
      totalRowsCount={rtcHistoryList.length}
      onSearch={handleCustomerSearch}
      onSortData={onSortData}
      onPageIndexChange={setCurrentPageIndex}
      onHandleRedirect={(searchInput: string)=> onHandleRedirect(searchInput)}
    />
  </>

}

export default RtcWarpper;