import { createSlice } from '@reduxjs/toolkit'
import MPAdapter from '../../adapters/MPMessengerAdapter';
import TelematicsUnitAdapter from '../../adapters/TelematicsAdapter';
import { getUserFullNameListByScaniaIds } from '../../adapters/UserAdapter'
import { forEach,  find, orderBy } from 'lodash';
import _ from 'lodash';

const getInitialState = (): RTCLogStateType => {
  const batchSearchInitialState: RTCLogStateType = {
    isLoading: false,
    hasError: false,
    errorMessage: "",
    unitMessages: [],
    isNotified: null,
    telematicsUnitEquipmentInfo: null,
    areContentsLoading: false,
    rtcLogContents: "",
    isRequestProcessing: false,
    logContentsMessageId: "",
    searchEquipmentReference: ""
  };
  return batchSearchInitialState
}
// Slice
const slice = createSlice({
  name: 'batchSearchState',
  initialState: getInitialState(),
  reducers: {
    updateIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    updateError: (state, action) => {
      state.hasError = action.payload !== '';
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    updateUniMessages: (state, action) => {
      state.unitMessages = action.payload
    },
    updateIsNotified: (state, action) => {
      state.isNotified = action.payload
    },
    updateUnitEquipmentInfo: (state, action) => {
      state.telematicsUnitEquipmentInfo = action.payload
    },
    updateContentInfo: (state, action) => {
      const { data, messageId } = action.payload
      state.rtcLogContents = data;
      state.logContentsMessageId = messageId;
    },
    updateAreContentsLoading: (state, action) => {
      state.areContentsLoading = action.payload
    },
    updateIsRequestProcessing: (state, action) => {
      state.isRequestProcessing = action.payload
    },
    setSearchEquipmentReference: (state, action) => {
      state.searchEquipmentReference = action.payload
    }
  },
});
export default slice.reducer

// Actions
const { updateIsLoading, updateError, updateUniMessages, updateIsNotified, updateUnitEquipmentInfo,
  updateContentInfo, updateAreContentsLoading, updateIsRequestProcessing, setSearchEquipmentReference } = slice.actions;

const LoadUnitMessage = (externalEquipmentReference: string) => async (dispatch: any) => {
  try {
    dispatch(updateIsLoading(true));
    dispatch(updateUniMessages([]));
    const res = await MPAdapter.fetchSupportMessages(externalEquipmentReference)
    if (res.data && res.data.length > 0) {
      const uniqueScaniaIds = _.chain(res.data)
                                  .map((row)=>{
                                    return row.sendBy && row.sendBy.toLowerCase();
                                  })
                                  .compact()
                                  .uniq()
                                  .value()
                                  
      const namesReponse = await getUsers(uniqueScaniaIds)
      forEach(res.data, function (message) {
        if (message.sendBy) {
          var matchedProfile = find(namesReponse, { 'scaniaId': message.sendBy.toLowerCase() });
          if (matchedProfile) {
            message.sendByFullName = matchedProfile.fullName;
          }else{
            message.sendByFullName =  message.sendBy
          }

        }
      });
    }
    const sortedData = orderBy(res.data, "timeSave", "desc");
    dispatch(updateUniMessages(sortedData));
    dispatch(updateIsLoading(false));
  } catch (e: any) {
    dispatch(updateError(e.message));
    return console.error(e);
  }
}

export const getUnitMessages = (externalEquipmentReference: string) => async (dispatch: any) => {
  try {
    dispatch(updateIsLoading(true));
    dispatch(setSearchEquipmentReference(externalEquipmentReference));
    dispatch(await LoadUnitMessage(externalEquipmentReference));
    const notificationResponse = await MPAdapter.fetchNotification(externalEquipmentReference)
    const unitInfoResponse = await TelematicsUnitAdapter.fetchEquipmentInformation(externalEquipmentReference)
    unitInfoResponse.data.isSupported = true;
    if (unitInfoResponse.data.rtcType === 'C200' && unitInfoResponse.data.rtcVersion < 2.4) {
      unitInfoResponse.data.isSupported = false;
    }
    dispatch(updateIsNotified(notificationResponse.data && notificationResponse.data.length > 0));
    dispatch(updateUnitEquipmentInfo(unitInfoResponse.data));
    dispatch(updateIsLoading(false));
  } catch (e: any) {
    dispatch(updateError(e.message));
    return console.error(e);
  }
}

export const getRtcLogsContents = (unitMessageId: string) => async (dispatch: any) => {
  try {
    dispatch(updateAreContentsLoading(true));
    const msgContentsResponse = await MPAdapter.getMessageContents(unitMessageId);
    dispatch(updateContentInfo({ data: msgContentsResponse.data, messageId: unitMessageId }));
    dispatch(updateAreContentsLoading(false));
  } catch (e: any) {
    dispatch(updateError(e.message));
    return console.error(e);
  }
}

export const requestOpenCloseSshPort = (externalEquipmentReference: string, openCloseFlag: boolean, vin: string) => async (dispatch: any) => {
  try {
    dispatch(updateIsRequestProcessing(true));
    const response = await MPAdapter.switchSSHPort(externalEquipmentReference, openCloseFlag);
    if (response.status === 200) {
      dispatch(createNotification(externalEquipmentReference, vin));
      dispatch(LoadUnitMessage(externalEquipmentReference));
    }
    dispatch(updateIsRequestProcessing(false));
  } catch (e: any) {
    dispatch(updateError(e.message));
    return console.error(e);
  }
}
export const requestRtcLogs = (externalEquipmentReference: string, vin: string) => async (dispatch: any) => {
  try {
    dispatch(updateIsRequestProcessing(true));
    const response = await MPAdapter.requestRtcLog(externalEquipmentReference);
    dispatch(createNotification(externalEquipmentReference, vin));
    if (response.status === 200) {
      dispatch(LoadUnitMessage(externalEquipmentReference));
    }
    dispatch(updateIsRequestProcessing(false));
  } catch (e: any) {
    dispatch(updateError(e.message));
    return console.error(e);
  }
}
export const createNotification = (externalEquipmentReference: string, vin: string) => async (dispatch: any) => {
  try {
    dispatch(updateIsLoading(true));
    await MPAdapter.createNotification(externalEquipmentReference, vin);
    const notificationResponse = await MPAdapter.fetchNotification(externalEquipmentReference);
    dispatch(updateIsNotified(notificationResponse.data && notificationResponse.data.length > 0));
    dispatch(updateIsLoading(false));
  } catch (e: any) {
    dispatch(updateError(e.message));
    return console.error(e);
  }
}
export const resetError = () => async (dispatch: any) => {
  dispatch(updateError(''));
}

const getUsers = async (uniqueScaniaIds: string[]) => {
  let finalResponse = [] as ShortProfileType[]
  try {
    const response = await getUserFullNameListByScaniaIds(uniqueScaniaIds);
    finalResponse = response.data;
  } catch (e) {
    console.error("Error Occurred while Retrieving Users", e);
  }
  finally {
    return finalResponse;
  }
}
