import { createSlice } from '@reduxjs/toolkit'

import { usersStatistics, usersSearch } from '../../adapters/UserStatisticsAdapter';

// Slice
const slice = createSlice({
    name: 'user',
    initialState: {
        userStatistics: null,
        prefix: '',
        searchList: null
    },
    reducers: {
        UserStaticsSuccess: (state, action) => {
            state.userStatistics = action.payload;
        },
        updatePrefixSuccess: (state, action) => {
            state.prefix = action.payload
        },
        updateSearchListSuccess: (state, action) => {
            state.searchList = action.payload
        }
    },
});
export default slice.reducer

// Actions
const { UserStaticsSuccess, updatePrefixSuccess, updateSearchListSuccess } = slice.actions

export const fetchUserStatistics = () => async (dispatch: any) => {
    try {
        const res = await usersStatistics()
        dispatch(UserStaticsSuccess(res.data));
    } catch (e) {
        return console.error(e);
    }
}

export const updateprefix = (value: string) => async (dispatch: any) => {
    try {
        dispatch(updatePrefixSuccess(value));
    } catch (e) {
        return console.error(e);
    }
}

export const fetchUserSearch = (prefix: string) => async (dispatch: any) => {
    try {
        const res = await usersSearch(prefix)
        dispatch(updateSearchListSuccess(res.data));
    } catch (e) {
        return console.error(e);
    }
}