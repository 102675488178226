
import React from 'react';
import VehicleDetails from './vehicleDetails';
import VehicleHistory from './vehicleHistory';

interface VehicleWarpperPropTyps {
    vehicleDetails?: VehicleDetails
    vehicleHistoryDataList: Array<VehicleHistory>
    isLoading: boolean
    isVehicleHistoryLoading: boolean
    onHandleRedirect: (searchInput: string) => void
}

const VehicleWarpper = ({ vehicleDetails, vehicleHistoryDataList, isLoading, isVehicleHistoryLoading, onHandleRedirect }: VehicleWarpperPropTyps) => {


return <>
        <VehicleDetails vehicleDetails={vehicleDetails} />
        <VehicleHistory vehicleHistoryDataList={vehicleHistoryDataList} onHandleRedirect={(searchInput: string)=> onHandleRedirect(searchInput)} isVehicleHistoryLoading={isVehicleHistoryLoading} />
    </>
}

export default VehicleWarpper;