import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { t } from 'i18next';
import { prepareChartData } from "./timeLineChartHelper";

am4core.useTheme(am4themes_animated);

interface ChartPropsTypes {
  positionMessages: any[]
  startTime?: Date | string
  endTime?: Date | string
}
class TimeLineChart extends Component<ChartPropsTypes> {
  chart: any;
  messagesCount: number = 0;

  componentDidMount() {
    let chart = am4core.create("time-line-chart", am4charts.XYChart);
    chart.data = prepareChartData(this.props.positionMessages, this.props.startTime, this.props.endTime, t);

    chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect
    chart.paddingRight = 20;


    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.fullWidthTooltip = true;
    dateAxis.tooltipDateFormat = 'HH:mm, d MMMM';
    dateAxis.dateFormatter = new am4core.DateFormatter();
    dateAxis.dateFormatter.utc = true; // Use UTC

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = 'Number of messages';
    valueAxis.renderer.minWidth = 35;

    var series = chart.series.push(new am4charts.StepLineSeries());
    series.dataFields.dateX = 'date';
    series.dataFields.valueY = 'messageCount';
    series.tooltipText = t("TS_core:NumberOfMessages") + ': [bold]{valueY}[/]';
    series.noRisers = true;
    series.strokeWidth = 2;
    series.fillOpacity = 0.5;

    var hiddenSeries = chart.series.push(new am4charts.LineSeries());
    hiddenSeries.dataFields.dateX = 'date';
    hiddenSeries.dataFields.valueY = 'messageCount';
    hiddenSeries.tooltipText = '{toolTip}';
    hiddenSeries.strokeOpacity = 0;

    var bullet = hiddenSeries.bullets.push(new am4charts.Bullet());
    var image = bullet.createChild(am4core.Image);
    image.width = 15;
    image.height = 15;
    image.horizontalCenter = 'middle';
    image.verticalCenter = 'middle';
    image.propertyFields.href = 'icon';
    image.dy = -15;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.fullWidthLineX = true;
    chart.cursor.xAxis = dateAxis;
    chart.cursor.lineX.strokeOpacity = 0;
    chart.cursor.lineX.fill = am4core.color('#000');
    chart.cursor.lineX.fillOpacity = 0.1;

    let scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    chart.scrollbarX = scrollbarX;

    this.chart = chart;
    this.messagesCount = this.chart.data.length;
  }

  componentDidUpdate(prevProps: Readonly<ChartPropsTypes>): void {
    if (this.messagesCount !== prevProps.positionMessages.length) {
      this.chart.data = prepareChartData(this.props.positionMessages, this.props.startTime, this.props.endTime, t);
      this.messagesCount = prevProps.positionMessages.length;
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <div id="time-line-chart" style={{ width: "100%", height: "500px" }}></div>
    );
  }
}

export default TimeLineChart;