import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import dayjs from 'dayjs';
import { t } from 'i18next';
import _ from 'lodash';
import dayJsdurationPuglin from 'dayjs/plugin/duration';
import { HIGH, MEDIUM, getDelay } from '../chartHelper';
dayjs.extend(dayJsdurationPuglin);

am4core.useTheme(am4themes_animated);

interface PieChartPropsTypes {
  positionMessages: any[]
}

class PieChart extends Component<PieChartPropsTypes> {
  chart: any;
  messagesCount: number = 0;

  componentDidMount() {
    //Init chart instance

    let chart = am4core.create('pie-chart-div', am4charts.PieChart);
    chart.data = this.prepareChartData(this.props.positionMessages);

    //Set chart layout
    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'messageCount';
    pieSeries.dataFields.category = 'delayLevel';
    pieSeries.colors.list = [
      am4core.color('#FF2340'),
      am4core.color('#F1C21B'),
      am4core.color('#1DAB8B')
    ];
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    this.chart = chart
    this.messagesCount = this.chart.data.length;
  }

  componentDidUpdate(prevProps: Readonly<PieChartPropsTypes>): void {
    if (this.messagesCount !== prevProps.positionMessages.length) {      
      this.chart.data = this.prepareChartData(this.props.positionMessages);
      this.messagesCount = prevProps.positionMessages.length;
    }    
  }

  prepareChartData = (messages: any[]) => {
    //Prepare chart data
    let high = 0;
    let medium = 0;
    let low = 0;

    _.forEach(messages, function (m) {
      var duration = dayjs.duration(dayjs(m.timeSaved ? m.timeSaved : m.timeMessage).diff(dayjs(m.gpsTimestamp)));
      var delay = duration.asSeconds();
      if (getDelay(delay) === HIGH) {
        high = high + 1;
      } else if (getDelay(delay) === MEDIUM) {
        medium = medium + 1;
      } else {
        low = low + 1;
      }
    });

    if (low === 0 && medium === 0 && high === 0) {
      return [];
    }

    return [
      {
        delayLevel: t("TS_core:High"),
        messageCount: high,
        color: '#FF2340'
      },
      {
        delayLevel: t("TS_core:Medium"),
        messageCount: medium,
        color: '#F1C21B'
      },
      {
        delayLevel: t("TS_core:Low"),
        messageCount: low,
        color: '#1DAB8B'
      }
    ];
  }


  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <div id="pie-chart-div" className="sdds-u-mt2" style={{ width: "500px", height: "200px" }}></div>
    );
  }
}

export default PieChart;