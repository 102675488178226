import React from 'react';
import { DataCell, DataRow, DataTable, Pagination, Spinner } from '@cd/sdds-common-components-react';
import { t } from "i18next";
import DateTimeSeperator from '../../CommunicationHistory/DateTimeSeperator';
import { RedirectSvg } from '@cd/sdds-common-components-react/lib/esm/components/assets';

interface RtcHistoryTablePropType {
  rtcHistoryDataList: Array<RtcHistory>
  isLoading: boolean
  currentPageIndex: number
  totalRowsCount: number;
  onSearch?: (searchString: string) => void
  onSortData?: (columnId: string, sortDirection: string) => void
  onPageIndexChange?: (page: number) => void
  onHandleRedirect: (searchInput: string) => void
}

const RtcHistory = ({ rtcHistoryDataList, isLoading, currentPageIndex, totalRowsCount, onSearch, onSortData, onPageIndexChange, onHandleRedirect }: RtcHistoryTablePropType) => {

  const columnsConfig: Array<RtcHistoryColumnConfigType> = [
    { columnid: 'timeRegistered', title: t("TS_core:TimeRegistered"), sortable: true, defaultSortDirection: "desc" },
    { columnid: 'externalEquipmentReference', title: t("TS_core:ExternalEquipmentReference"), sortable: true },
    { columnid: 'vin', title: t("TS_core:VIN"), sortable: true, },
    { columnid: 'action', title: t(""), sortable: false }
  ];

  return <>
    <div className='history rtc-table'>
      <DataTable
        onSearchChange={onSearch}
        onSort={onSortData}
        title={`${t("TS_core:RTCHistory")}`}
        columns={columnsConfig}
        actionBar={<>
          <>
          </>
        </>}
      >
        {rtcHistoryDataList && rtcHistoryDataList.length > 0 && rtcHistoryDataList.map((row: any) => {
          return <DataRow>
            {columnsConfig?.map((col) => {
              switch (col.columnid) {
                case 'timeRegistered':
                  return <DataCell cssClassName="freeze"><DateTimeSeperator inputDateTime={row[col.columnid]} /></DataCell>;
                case 'externalEquipmentReference':
                  return <>
                    <DataCell cssClassName="freeze">
                      {row.externalEquipmentReference && <a href={`https://equipment${process.env.REACT_APP_CSTT_DOMAIN}/communication/` + row[col.columnid]} target="_blank" rel="noopener noreferrer">
                        <RedirectSvg />
                        <span>{row[col.columnid]}</span></a>
                      }
                    </DataCell>
                  </>
                case 'action':
                  return <>
                    <DataCell cssClassName="freeze" >
                        <span className="button sdds-link sdds-link--no-underline" role="button" onKeyDown={() => { }} onClick={() => onHandleRedirect(row.externalEquipmentReference ? row.externalEquipmentReference : row.vin )}> {`${t("TS_core:ViewVehicleHistory")}`}</span>
                    </DataCell>
                  </>
                default:
                  return <DataCell cssClassName="freeze">{row[col.columnid]}</DataCell>;
              }
            })}
          </DataRow>;
        })}
        {(!rtcHistoryDataList || rtcHistoryDataList.length === 0) && isLoading &&
          <DataRow>
            <DataCell colSpan={3}><Spinner size="md" /></DataCell>
          </DataRow>
        }
        {(!rtcHistoryDataList || rtcHistoryDataList.length === 0) && !isLoading &&
          <DataRow>
            <DataCell colSpan={3}>{t("TS_core:NoDataFound")}</DataCell>
          </DataRow>
        }
      </DataTable>
      <Pagination currentPage={currentPageIndex} totalCount={totalRowsCount} pageSize={20} onPageChange={onPageIndexChange} />
    </div>
  </>
}

export default RtcHistory;