import { AxiosPromise } from 'axios';
import { csttFacadeApi } from '@cd/sdds-common-components-react';

const getVehicleDetails = (extEquipmentRef: string): AxiosPromise<VehicleDetails> => {
  return csttFacadeApi.get(`/csttfacade/vehiclertchistory/equipment/${extEquipmentRef}`);
}

const getVehicleHistory = (extEquipmentRef: string): AxiosPromise<Array<VehicleHistory>> => {
  return csttFacadeApi.get(`/csttfacade/vehiclertchistory/equipment/${extEquipmentRef}/history`);
}

const getRtcDetails = (extGatewayRef: string): AxiosPromise<RtcDetails> => {
  return csttFacadeApi.get(`/csttfacade/vehiclertchistory/gateway/${extGatewayRef}`);
}

const getRtcHistory = (extGatewayRef: string): AxiosPromise<Array<RtcHistory>> => {
  return csttFacadeApi.get(`/csttfacade/vehiclertchistory/gateway/${extGatewayRef}/history`);
}

const searchByRegOrVin = (query: string): AxiosPromise<Array<EquipmentDetailType>> => {
  return csttFacadeApi.get(`/csttfacade/equipment/v1/search?query=${query}`);
}

const searchByMobileIdImsiMsisdn = (query: string): AxiosPromise<Array<RtcType>> => {
  return csttFacadeApi.get(`/csttfacade/equipment/v1/search/${query}`);
}

const getEquipmentGatewayRelation = (equipmentRef: string): AxiosPromise<EquipmentGatewayType> => {
  return csttFacadeApi.get(`/csttfacade/vehiclertchistory/equipment/${equipmentRef}/gatewayrelation`);
}

export { getVehicleDetails, getVehicleHistory, getRtcDetails, getRtcHistory, searchByRegOrVin, searchByMobileIdImsiMsisdn, getEquipmentGatewayRelation }