import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './VehicleRtcSearch.scss'
import { useAppSelector } from '../../redux/store';
import { TdsRadioButton } from '@scania/tegel-react';

interface SearchVehicleRtcPropTyps {
    onSearch: (selectedEquipmentType: string, searchInputValue: string) => void
    equipmentType: string
    searchInput: string
}

const SearchVehicleRtc = ({ onSearch, equipmentType, searchInput }: SearchVehicleRtcPropTyps) => {
    const { t } = useTranslation();
    const { isLoading } = useAppSelector<VehicleRtcHistoryType>(state => state.vehicleRtcHistory);
    const tdsComponentRef = useRef<any>(null);
    const [selectedEquipmentType, setSelectedEquipmentType] = useState<string>('vehicle');
    const [deviceRef, setDeviceRef] = useState<string>('');
    const [selectedPlaceholderText, setSelectedPlaceholderText] = useState<string>(t("TS_core:SearchVehicleHistoryText"));
    
    useEffect(() => {
        if (tdsComponentRef.current) {
            tdsComponentRef.current.addEventListener("tdsChange", (evt: any) => {
                setSelectedEquipmentType(evt.detail.radioId);
            });
        }
    }, [])

    useEffect(() => {
        if (selectedEquipmentType === 'vehicle')
            setSelectedPlaceholderText(t("TS_core:SearchVehicleHistoryText"));
        else if (selectedEquipmentType === 'rtc')
            setSelectedPlaceholderText(t("TS_core:SearchRtcHistoryText"));
    }, [selectedEquipmentType]);

    useEffect(() => {
        setDeviceRef(searchInput);
        if(equipmentType === ''){
            setSelectedEquipmentType('vehicle');
        }else{
            setSelectedEquipmentType(equipmentType);
        }  
    }, [equipmentType, searchInput])

    const searchByEquipmentRef = () => async () => {
        onSearch(selectedEquipmentType, deviceRef);
    }

    return <div className='search-box'>
        <div className='tds-headline-06 helper-text'>
            {selectedEquipmentType === 'vehicle' ? t("TS_core:SearchByVehicle") : t("TS_core:SearchByRTC")} -
            <span className='desc-text'> {selectedEquipmentType === 'vehicle' ? t("TS_core:SearchByVehicleText") : t("TS_core:SearchByRTCText")}</span>
        </div>
        <div className='container'>
            <fieldset className="item" ref={tdsComponentRef}>
                <div className='radio-button-container'>
                    <TdsRadioButton
                        name="rb-example"
                        value="vehicle"
                        radio-id="vehicle"
                        checked={selectedEquipmentType ==='vehicle'}>
                            <div slot="label">{t("TS_core:Vehicle")}</div>
                    </TdsRadioButton>

                    <TdsRadioButton 
                        name="rb-example"
                        value="rtc"
                        radio-id="rtc"
                        checked={selectedEquipmentType === 'rtc'}>
                        <div slot="label">RTC</div>
                    </TdsRadioButton>
                </div>

            </fieldset>

            <input placeholder={selectedPlaceholderText} className='itemInput' type="text" id="deviceId" value={deviceRef} onChange={e => setDeviceRef(e.target.value)} />

            <tds-button className='item' type="button" variant="primary" size="lg" disabled={isLoading} text={t("TS_core:RequestHistory")} onClick={searchByEquipmentRef()} />
        </div>
    </div>
};

export default SearchVehicleRtc;