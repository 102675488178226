import React, {useEffect,useState, memo} from 'react'

const ColumnFormatter =({ data }: any) => {
    const [currentVal, setCurrentVal] = useState('');

    useEffect(() => {
        if(typeof data === 'string'){
            setCurrentVal(JSON.stringify(data).slice(1,-1))
        }
        else if(Array.isArray(data)){
            setCurrentVal(JSON.stringify(data))
        }
        else{
            setCurrentVal(JSON.stringify(data).toString())
        }  
    },[data]);
    
    return <>  
        {currentVal}
    </>;
  }
  export default memo(ColumnFormatter)