import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { fetchCSDDefinitionByid } from '../../../redux/store/CSDsStore'
import { useAppDispatch } from '../../../redux/store'
import { DocsFunctionTrigger } from '@cd/sdds-common-components-react'
import { UserContext } from '../../../appContext'
interface CsdDetailsPropType {
  csdDefinition: CsdDefinitionType | null,
  csd: CsdType
  selectedVehicle: VehicleType
}

const CsdDefinition = ({ csd, csdDefinition, selectedVehicle }: CsdDetailsPropType) => {
  const dispatch = useAppDispatch();
  const { settings } = useContext(UserContext);

  useEffect(() => {
    if (!csdDefinition && csd) {
      dispatch(fetchCSDDefinitionByid(csd.definitionId, csd.systemName));
    }
    else if (csdDefinition && csd && csdDefinition.id !== csd.definitionId) {
      dispatch(fetchCSDDefinitionByid(csd.definitionId, csd.systemName));
    }
  }, [csd])

  const { t } = useTranslation()
  return <>
    {csdDefinition && <>
      <div className='white-box'>
        <div>
          <span className='sdds-headline-06'>{t("TS_core:SystemName")}</span>
          <span>{csdDefinition.systemName}</span>
        </div>
        <div>
          <span className='sdds-headline-06'>{t("TS_core:Id")}</span>
          <span>{csdDefinition.id}</span>
        </div>
        <div>
          <span className='sdds-headline-06'>{t("TS_core:Type")}</span>
          <span>{csdDefinition.type}</span>
        </div>
        <div>
          <span className='sdds-headline-06'>{t("TS_core:Group")}</span>
          <span>{csdDefinition.group}</span>
        </div>
      </div>
      <div className='label sdds-headline-07 sdds-u-mt3'>{t("TS_core:Title")}</div>
      <div className='sdds-u-mt1 sdds-u-mb1'>{csdDefinition.message.heading}</div>

      <div className='label sdds-headline-07 sdds-u-mt3 '>{t("TS_core:Deviation")}</div>
      <div className='sdds-u-mt1 sdds-u-mb1'>{csdDefinition.message.deviation}</div>

      <div className='label sdds-headline-07 sdds-u-mt3 red'>{t("TS_core:Cause")}</div>
      <div className='sdds-u-mt1 sdds-u-mb1'>{csdDefinition.message.cause || "-"}</div>

      <div className='label sdds-headline-07 sdds-u-mt3'>{t("TS_core:SystemReaction")}</div>
      <div className='sdds-u-mt1 sdds-u-mb1'>{csdDefinition.message.systemReaction}</div>

      <div className='label sdds-headline-07 sdds-u-mt3 green'>{t("TS_core:Action")}</div>
      <div className='sdds-u-mt1 sdds-u-mb1'>
        <div className='sdds-headline-07'>{t("TS_core:Text")}</div>
        <div className='sdds-body-02'>{csdDefinition.message.action.text || "-"}</div>
        <div className='sdds-headline-07 sdds-u-mt1'>{t("TS_core:Url")}</div>
        <div className='sdds-body-02'>{csdDefinition.message.action.url || '-'}</div>
        <div className='sdds-headline-07 sdds-u-mt1'>{t("TS_core:QuickFix")}</div>
        {/* Todo: Add Docsfunction Button  */}
        <div className='sdds-body-02'>
          {csdDefinition.message.action.docsFunctionIds && csdDefinition.message.action.docsFunctionIds.map((functionId: string) => {
            return <DocsFunctionTrigger functionId={functionId} languageCode={settings.language} />
          })
          }
        </div>

      </div>

      <div className='label sdds-headline-07 sdds-u-mt3'>{t("TS_core:EngineeringComment")}</div>
      <div className='sdds-u-mt1'>{csdDefinition.message.engineeringComment || "-"}</div>
    </>
    }
  </>
}

export default CsdDefinition;