import { createSlice } from '@reduxjs/toolkit'

import { getVehicleDetails, getVehicleHistory, getRtcDetails, getRtcHistory, searchByRegOrVin, searchByMobileIdImsiMsisdn, getEquipmentGatewayRelation } from '../../adapters/VehicleRtcAdapter';
import { utcFormatFromString } from '../../helpers/timeFormatter';

const getInitialState = (): VehicleRtcHistoryType => {
	const initialState: VehicleRtcHistoryType = {
		vehicleDetails: undefined,
		vehicleHistoryList: [],
		rtcDetails: undefined,
		rtcHistoryList: [],
		isLoading: false,
		isRtcHistoryLoading: false,
		isVehicleHistoryLoading: false,
		hasError: false,
		errorMessage: ''
	};
	return initialState
}

// Slice
const slice = createSlice({
	name: 'vehicleRtcHistory',
	initialState: getInitialState,
	reducers: {
		SetIsLoading: (state, action) => {
			state.isLoading = action.payload;
		},
		SetIsRtcHistoryisLoading: (state, action) => {
			state.isRtcHistoryLoading = action.payload;
		},
		SetIsVehicleHistoryisLoading: (state, action) => {
			state.isVehicleHistoryLoading = action.payload;
		},
		SetErrorMessage: (state, action) => {
			state.errorMessage = action.payload;
		},
		SetHasError: (state, action) => {
			state.hasError = action.payload
		},
		UpdateVehicleDetails: (state, action) => {
			state.vehicleDetails = action.payload;
		},
		UpdateVehicleHistory: (state, action) => {
			state.vehicleHistoryList = action.payload
		},
		UpdateRtcDetails: (state, action) => {
			state.rtcDetails = action.payload
		},
		UpdateRtcHistory: (state, action) => {
			state.rtcHistoryList = action.payload
		}
	},
});
export default slice.reducer

// Actions
const { SetIsLoading, SetErrorMessage, SetHasError, UpdateVehicleDetails, UpdateVehicleHistory, UpdateRtcDetails, UpdateRtcHistory, SetIsRtcHistoryisLoading, SetIsVehicleHistoryisLoading } = slice.actions

export const fetchVehicleDetails = (equipmentRef: string) => async (dispatch: any) => {
	try {
		resetErrors();
		dispatch(SetIsLoading(true));
		const res = await getVehicleDetails(equipmentRef)
		dispatch(UpdateVehicleDetails(res.data));
		dispatch(SetIsLoading(false));
	} catch (e: any) {
		dispatch(SetIsLoading(false));
		dispatch(SetErrorMessage(e.message));
		dispatch(SetHasError(true));
		return console.error(e.message);
	}
}

export const fetchVehicleHistory = (equipmentRef: string) => async (dispatch: any) => {
	try {
		resetErrors();
		dispatch(SetIsVehicleHistoryisLoading(true));
		const res = await getVehicleHistory(equipmentRef)
		dispatch(UpdateVehicleHistory(res.data));
		dispatch(SetIsVehicleHistoryisLoading(false));
	} catch (e: any) {
		dispatch(SetIsVehicleHistoryisLoading(false));
		dispatch(SetErrorMessage(e.message));
		dispatch(SetHasError(true));
		return console.error(e.message);
	}
}

export const fetchRtcDetails = (gatewayRef: string) => async (dispatch: any) => {
	try {
		resetErrors();
		dispatch(SetIsLoading(true));
		const res = await getRtcDetails(gatewayRef)
		dispatch(UpdateRtcDetails(res.data));
		dispatch(SetIsLoading(false));
	} catch (e: any) {
		dispatch(SetIsLoading(false));
		dispatch(SetErrorMessage(e.message));
		dispatch(SetHasError(true));
		return console.error(e.message);
	}
}

export const fetchRtcHistory = (gatewayRef: string) => async (dispatch: any) => {
	try {
		resetErrors();
		dispatch(SetIsRtcHistoryisLoading(true));
		const res = await getRtcHistory(gatewayRef)
		let rtcHistoryResponse = res.data.map((rtcHistory: RtcHistory) => {
			return {
				timeRegistered: rtcHistory?.timeRegistered ? utcFormatFromString(rtcHistory?.timeRegistered) : '',
				externalEquipmentReference: rtcHistory?.externalEquipmentReference,
				vin: rtcHistory?.vin
			}
		}).sort((a: any, b: any) => a.timeRegistered < b.timeRegistered ? 1 : -1);
		dispatch(UpdateRtcHistory(rtcHistoryResponse));
		dispatch(SetIsRtcHistoryisLoading(false));

	} catch (e: any) {
		dispatch(SetIsRtcHistoryisLoading(false));
		dispatch(SetErrorMessage(e.message));
		dispatch(SetHasError(true));
		return console.error(e.message);
	}
}

export const searchByVin = (vin: string) => async (dispatch: any) => {
	try {
		resetErrors();
		dispatch(SetIsLoading(true));
		dispatch(SetIsVehicleHistoryisLoading(true));
		const res = await searchByRegOrVin(vin)
		if (res) {
			var equipmentRef = '';
			// Pick the first one. Serch string should be fully complete
			if (res.data && res.data.length > 0)
				equipmentRef = res.data[0].externalEquipmentReference

			if (equipmentRef === '')
				throw new Error('equipmentRef not found by searching with VIN');

			const details = await getVehicleDetails(equipmentRef)
			dispatch(UpdateVehicleDetails(details.data));
			const history = await getVehicleHistory(equipmentRef)
			dispatch(UpdateVehicleHistory(history.data));
		}
		dispatch(UpdateRtcHistory(res.data));
		dispatch(SetIsLoading(false));
		dispatch(SetIsVehicleHistoryisLoading(false));

	} catch (e: any) {
		dispatch(SetIsVehicleHistoryisLoading(false));
		dispatch(SetIsLoading(false));
		dispatch(SetErrorMessage(e.message));
		dispatch(SetHasError(true));
		return console.error(e.message);
	}
}

export const searchByMobileId = (query: string) => async (dispatch: any) => {
	try {
		resetErrors();
		dispatch(SetIsRtcHistoryisLoading(true));
		const rtc = await searchByMobileIdImsiMsisdn(query);
		if (rtc && rtc.data && rtc.data.length > 0) {
			var gatewayRef = '';

			// Pick the first one. Serch string should be fully complete
			const eqGwRelation = await getEquipmentGatewayRelation(rtc.data[0].externalEquipmentReference);
			if (eqGwRelation)
				gatewayRef = eqGwRelation.data.gatewayReference;

			if (gatewayRef === '')
				throw new Error('gatewayRef not found by searching with mobile id');

			const details = await getRtcDetails(gatewayRef)
			dispatch(UpdateRtcDetails(details.data));
			const history = await getRtcHistory(gatewayRef)
			dispatch(UpdateRtcHistory(history.data));
		}

		dispatch(SetIsRtcHistoryisLoading(false));
	} catch (e: any) {
		dispatch(SetIsRtcHistoryisLoading(false));
		dispatch(SetErrorMessage(e.message));
		dispatch(SetHasError(true));
		return console.error(e.message);
	}
}


export const resetErrors = () => async (dispatch: any) => {
	dispatch(SetErrorMessage(''));
	dispatch(SetHasError(false));
}

export const sortRtcHistoryeDataList = (sortedDataList: Array<any>) => async (dispatch: any) => {
	try {
		dispatch(UpdateRtcHistory(sortedDataList));
	} catch (error: any) {
		dispatch(SetErrorMessage(error.response.data));
		return console.error(error.response.data);
	}
}

export const cleareVehicleDetails = () => async (dispatch: any) => {
	try {
		dispatch(UpdateVehicleDetails(undefined));
		dispatch(UpdateVehicleHistory([]));
		dispatch(SetIsLoading(false));
	} catch (error: any) {
		dispatch(SetErrorMessage(error.response.data));
		return console.error(error.response.data);
	}
}

export const cleareRTCDetails = () => async (dispatch: any) => {
	try {
		dispatch(UpdateRtcDetails(undefined));
		dispatch(UpdateRtcHistory([]));
		dispatch(SetIsLoading(false));
		dispatch(SetIsRtcHistoryisLoading(false));
	} catch (error: any) {
		dispatch(SetErrorMessage(error.response.data));
		return console.error(error.response.data);
	}
}
