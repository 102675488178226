
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TruckSvg } from '@cd/sdds-common-components-react';

interface VehicleDetailsPropTyps {
    vehicleDetails?: VehicleDetails
}

const VehicleDetails = ({ vehicleDetails }: VehicleDetailsPropTyps) => {

    const { t } = useTranslation()

return <>
       <div className='details'>
        <h2><TruckSvg />{t("TS_core:VehicleDetails")}</h2>
        <ul>
            <li><span>{t("TS_core:ChassisNumber")}</span> {vehicleDetails?.chassisNr}</li>
            <li><span>{t("TS_core:Vin")}</span> {vehicleDetails?.vin}</li>
            <li><span>{t("TS_core:RegistrationNumber")}</span>{vehicleDetails?.regNumber}</li>
            <li><span>{t("TS_core:Manufacturer")}</span> {vehicleDetails?.manufacturer}</li>
            <li><span>{t("TS_core:RegistrationCountry")}</span> {vehicleDetails?.regCountry}</li>
        </ul>
        </div> 
        </>
}

export default VehicleDetails;