/* eslint-disable quote-props */
const csSchemaVariables = [{
  value: "processingLog",
  type: 'list',
  checked: false,
  dataTitle: 'list'
},
{
  value: "vehicleMovement",
  checked: false,
  dataTitle: ''
},
//Remove odometerICL from historical data popup
// {
//   value: "odometerICL",
//   checked: false,
//   type: 'metre'
// },
{
  value: "odometer",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "odometerICL",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "odometerDistance",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "totalReductantUsed",
  checked: false,
  type: 'centiliters',
  dataTitle: 'Centiliters'
},
{
  value: "fuelLevel",
  checked: false,
  type: 'percent',
  dataTitle: 'Percent'
},
{
  value: "distanceToFuelEmpty",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "timeToFuelEmpty",
  checked: false,
  type: 'second',
  dataTitle: 'Second'
},
{

  value: "ureaLevel",
  checked: false,
  type: 'percent',
  dataTitle: 'Percent'
},
{
  value: "distanceToUreaEmpty",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "timeToUreaEmpty",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "normalizedBatterySOC",
  checked: false,
  type: 'percent',
  dataTitle: 'Percent'
},
{
  value: "distanceToEnergyEmpty",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "timeToEnergyEmpty",
  checked: false,
  type: 'second',
  dataTitle: 'Second'
},
{
  value: "workingStateDriver1",
  checked: false,
  dataTitle: ''
},
{
  value: "workingStateDriver2",
  checked: false,
  dataTitle: ''
},
{
  value: "ambientAirTemperature",
  checked: false,
  type: 'celsius',
  dataTitle: 'Celsius'
},
{
  value: "engineCoolantTemperature",
  checked: false,
  type: 'celsius',
  dataTitle: 'Celsius'
},
{
  value: "wheelBasedSpeed",
  checked: false,
  type: 'm/s',
  dataTitle: 'm/s'
},
{
  value: "tachoSpeed",
  checked: false,
  type: 'm/s',
  dataTitle: 'm/s'
},
{
  value: "serviceBrakeAirPressureCircuit1",
  checked: false,
  type: 'pascal',
  dataTitle: 'Pascal'
},
{
  value: "serviceBrakeAirPressureCircuit2",
  checked: false,
  type: 'pascal',
  dataTitle: 'Pascal'
},
{
  value: "bellowPressureFrontAxleLeft",
  checked: false,
  type: 'pascal',
  dataTitle: 'Pascal'
},
{
  value: "bellowPressureFrontAxleRight",
  checked: false,
  type: 'pascal',
  dataTitle: 'Pascal'
},
{
  value: "bellowPressureRearAxleLeft",
  checked: false,
  type: 'pascal',
  dataTitle: 'Pascal'
},
{
  value: "bellowPressureRearAxleRight",
  checked: false,
  type: 'pascal',
  dataTitle: 'Pascal'
},
{
  value: "networkStrength",
  checked: false,
  type: 'percent',
  dataTitle: 'Percent'
},
{
  value: "combustionEngineMode",
  checked: false,
  dataTitle: ''
},
{
  value: "electricDriveMode",
  checked: false,
  dataTitle: ''
},
{
  value: "distanceNextService",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "distanceCoastingOutOfGear",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "distanceCCActive",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "distancePerformanceModeEconomy",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "distancePerformanceModeOffroad",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "distancePerformanceModePower",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "distancePerformanceModeStandard",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "distanceWithTrailer",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "distanceCoastingZeroTorque",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "distanceManualCoastingZeroTorque",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "distanceDrivingWithPTO",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "distanceDrivingWithoutPTO",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "distanceDrivingInPauseWithPTO",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "distanceDrivingInPauseWithoutPTO",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "distanceBrakePedalActive",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "distanceWithZeroTorqueCoordinator",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "distanceWithZeroTorqueManualCoordinator",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "distanceInLowSOCLimpHome",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "totalFuel",
  checked: false,
  type: 'centiliters',
  dataTitle: 'Centiliters'
},
{
  value: "fuelDrivingWithoutPTO",
  checked: false,
  type: 'centiliters',
  dataTitle: 'Centiliters'
},
{
  value: "fuelDrivingWithPTO",
  checked: false,
  type: 'centiliters',
  dataTitle: 'Centiliters'
},
{
  value: "fuelIdleWithoutPTO",
  checked: false,
  type: 'centiliters',
  dataTitle: 'Centiliters'
},
{
  value: "fuelIdleWithPTO",
  checked: false,
  type: 'centiliters',
  dataTitle: 'Centiliters'
},
{
  value: "totalGas",
  checked: false,
  type: 'gram',
  dataTitle: 'Gram'
},
{
  value: "gasDrivingWithoutPTO",
  checked: false,
  type: 'gram',
  dataTitle: 'Gram'
},
{
  value: "gasIdleWithoutPTO",
  checked: false,
  type: 'gram',
  dataTitle: 'Gram'
},
{
  value: "gasIdleWithPTO",
  checked: false,
  type: 'gram',
  dataTitle: 'Gram'
},
{
  value: "gasDrivingWithPTO",
  checked: false,
  dataTitle: ''
},
{
  value: "fuelWithCCActive",
  checked: false,
  type: 'centiliter',
  dataTitle: 'Centiliter'
},
{
  value: "distanceNotPlatooningWhenRecommended",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "distancePlatooningWhenPossible",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "distancePlatooningWhenRecommended",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "numberOfPlatooningOffAndAICCOffWhenPlatooningRecommended",
  checked: false,
  type: 'counter',
  dataTitle: 'counter'
},
{
  value: "numberOfPlatooningOffAndAICCOnWhenPlatooningRecommended",
  checked: false,
  type: 'counter',
  dataTitle: 'counter'
},
{
  value: "numberOfPlatooningOnAndAICCOffWhenPlatooningRecommended",
  checked: false,
  type: 'counter',
  dataTitle: 'counter'
},
{
  value: "numberOfPlatooningOnAndAICCOnWhenPlatooningRecommended",
  checked: false,
  type: 'counter',
  dataTitle: 'counter'
},
{
  value: "totalRuntime",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "runtimeDrivingWithoutPTO",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "runtimeDrivingWithPTO",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "runtimeIdleWithoutPTO",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "runtimeIdlePause",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "runtimeIdleWithPTO",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "timeOverrevving",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "timeOverspeeding",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "timeCCActive",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "runtimeNextService",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "timeBusDoorOpen",
  checked: false,
  dataTitle: ''
},
{
  value: "timeOutOfGreenBandDriving",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "timeInLowSOCLimpHome",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "timeCharging",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "numberOfBrakePedalApplications",
  checked: false,
  type: 'counter',
  dataTitle: 'counter'
},
{
  value: "numberOfHarshBrakes",
  checked: false,
  type: 'counter',
  dataTitle: 'counter'
},
{
  value: "numberOfHarshAccelerations",
  checked: false,
  type: 'counter',
  dataTitle: 'counter'
},
{
  value: "numberOfBrakePedalApplicationsWhenSpeedOverZero",
  checked: false,
  type: 'counter',
  dataTitle: 'counter'
},
{
  value: "numberOfWheelchairLifts",
  checked: false,
  type: 'counter',
  dataTitle: 'counter'
},
{
  value: "interactiveDriverTrainingVersion",
  checked: false,
  dataTitle: ''
},
{
  value: "accumulatedGradeHill",
  checked: false,
  dataTitle: ''
},
{
  value: "accumulatedGradeWear",
  checked: false,
  dataTitle: ''
},
{
  value: "accumulatedGradeAnticipation",
  checked: false,
  dataTitle: ''
},
{
  value: "accumulatedGradeGearSelection",
  checked: false,
  dataTitle: ''
},
{
  value: "accumulatedGradeHybridBrake",
  checked: false,
  dataTitle: ''
},
{
  value: "accumulatedWeightHill",
  checked: false,
  dataTitle: ''
},
{
  value: "accumulatedWeightWear",
  checked: false,
  dataTitle: ''
},
{
  value: "accumulatedWeightAnticipation",
  checked: false,
  dataTitle: ''
},
{
  value: "accumulatedWeightGearSelection",
  checked: false,
  dataTitle: ''
},
{
  value: "accumulatedWeightHybridBrake",
  checked: false,
  dataTitle: ''
},
{
  value: "trailerIdentifications",
  checked: false,
  dataTitle: ''
},
{
  value: "transportWork(tonne-kilometre)",
  checked: false,
  type: 'tonne-kilometre',
  dataTitle: 'tonne-kilometre'
},
{
  value: "engineSpeed",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "vehicleSpeedPeriodic",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "vehicleSpeed",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "accelerationPedalPosition",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "acceleration",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "retarderTorque",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "engineTorque",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "accelerationDuringBrake",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "selectedGear",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "currentGear",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "highAcceleration",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "engineTorqueAtCurrentSpeed",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "accumulatedTotalTime",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "accumulatedTimeIdle",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "accumulatedTimePTOInIdle",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "accumulatedTimePTOInDrive",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "accumulatedTimeForcedElectricMode",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "accumulatedTimeForcedChargingMode",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "accumulatedTimeIdleStartStopDeactivated",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "accumulatedTotalTimeInPause",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "accumulatedTimeInPauseIdle",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "accumulatedTimeInPausePTOInIdle",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "accumulatedTimeInPausePTOInDrive",
  checked: false,
  type: 'second',
  dataTitle: 'second'
},
{
  value: "accumulatedDistanceForcedChargingMode",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "accumulatedDistanceForcedElectricMode",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "accumulatedDistanceIdleStopStartDeactivated",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "accumulatedDistancePTOInDrive",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "accumulatedDistanceTotal",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "accumulatedDistanceInPausePTOInDrive",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "accumulatedDistanceInPauseTotal",
  checked: false,
  type: 'metre',
  dataTitle: 'Metre'
},
{
  value: "accumulatedEnergyIdle",
  checked: false,
  type: 'wh',
  dataTitle: 'wh'
},
{
  value: "accumulatedEnergyPTOInDrive",
  checked: false,
  type: 'wh',
  dataTitle: 'wh'
},
{
  value: "accumulatedEnergyPTOInIdle",
  checked: false,
  type: 'wh',
  dataTitle: 'wh'
},
{
  value: "accumulatedEnergyTotal",
  checked: false,
  type: 'wh',
  dataTitle: 'wh'
},
{
  value: "accumulatedFuelGaseousIdle",
  checked: false,
  type: 'gram',
  dataTitle: 'gram'
},
{
  value: "accumulatedFuelGaseousPTOInDrive",
  checked: false,
  type: 'gram',
  dataTitle: 'gram'
},
{
  value: "accumulatedFuelGaseousPTOInIdle",
  checked: false,
  type: 'gram',
  dataTitle: 'gram'
},
{
  value: "accumulatedFuelGaseousTotal",
  checked: false,
  type: 'gram',
  dataTitle: 'gram'
},
{
  value: "accumulatedFuelLiquidIdle",
  checked: false,
  type: 'centiliters',
  dataTitle: 'Centiliters'
},
{
  value: "accumulatedFuelLiquidPTOInDrive",
  checked: false,
  type: 'centiliters',
  dataTitle: 'Centiliters'
},
{
  value: "accumulatedFuelLiquidPTOInIdle",
  checked: false,
  type: 'centiliters',
  dataTitle: 'Centiliters'
},
{
  value: "accumulatedFuelLiquidTotal",
  checked: false,
  type: 'centiliters',
  dataTitle: 'Centiliters'
},
{
  value: "vehicleWeightSourceICLFiltered",
  checked: false,
  type: 'kilogram',
  dataTitle: 'kilogram'
},
{
  value: "vehicleWeightSourceICLAxle1Weight",
  checked: false,
  type: 'kilogram',
  dataTitle: 'kilogram'
},
{
  value: "vehicleWeightSourceICLAxle2Weight",
  checked: false,
  type: 'kilogram',
  dataTitle: 'kilogram'
},
{
  value: "vehicleWeightSourceICLAxle3Weight",
  checked: false,
  type: 'kilogram',
  dataTitle: 'kilogram'
},
{
  value: "vehicleWeightSourceICLAxle4Weight",
  checked: false,
  type: 'kilogram',
  dataTitle: 'kilogram'
},
{
  value: "vehicleWeightSourceICLAxle5Weight",
  checked: false,
  type: 'kilogram',
  dataTitle: 'kilogram'
},
{
  value: "vehicleWeightSourceICLTrailerWeight",
  checked: false,
  type: 'kilogram',
  dataTitle: 'kilogram'
},
{
  value: "vehicleWeightClass",
  checked: false,
  type: 'second',
  dataTitle: 'second'
}]

const fiSchemaVariables = [{
    value: "processingLog",
    type: 'list',
    checked: false,
    dataTitle: 'list'
  },
  {
    value: "drivingWithoutGPSSignal",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningBrakeTemperature",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningHeadlampFailure",
    checked: false,
    dataTitle: ''
  }, 
  {
    value: "warningEngineMil",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningHighEngineCoolantTemp",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningLowEngineOilPressure",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningEngineCoolantTemperature",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningDPFRegenerationState",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningLightingSystemFailure",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningGeneralFailure",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningPowerGenerationMalfunction",
    dataTitle: ''
  },
  {
    value: "warningTransmissionMalfunction",
    checked: false,
    dataTitle: ''
  },
  {
    value: "ignition",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningEngineMalfunction",
    checked: false,
    dataTitle: ''
  }, 
  {
    value: "warningRetarder",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningEngineAirFilter2",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningSeatbeltReminder",
    dataTitle: ''
  },
  {
    value: "tachoTampering",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningEngineOilTemperature",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningTorqueLimit",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningBatteryChargingCondition",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningEngineOil",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningEngineCoolantLevel",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningLowUreaLevel",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningOBDDegrationOverHeatProtection",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningDPFStatus",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningBrakeSystemMalfunction",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningEBS",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningEngineOilLevel",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningServiceDistance",
    dataTitle: ''
  },
  {
    value: "warningLowClutchFluidLevel",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningTransmissionFluidTemperature",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningClutchOverload",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningABSFailure",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningEngineEmissionSystemFailure",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningLowTirePressure",
    dataTitle: ''
  },
  {
    value: "warningLampLowUreaLevel",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningLowEngineCoolantWaterLevel",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningBrakeLiningWear",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningEBSTruckFailure",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningABSTrailerFailure",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningAPSFailure",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningRetarderFailure",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningTransmissionOilTemperatureAlarm",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningRetarderOilTemperatureAlarm",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningOBDSystemMalfunctionEngineMIL",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningTorqueLimitIndication",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningEngineAirFilter",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningParticulateFilterRegeneration",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningParticulateFilterRegenerationAlarm",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningParticulateFilterRegenerationIndication",
    checked: false,
    dataTitle: ''
  },
  {
    value: "warningTirePressure",
    checked: false,
    dataTitle: ''
  },
  {
    value: "trailerConnected",
    checked: false,
    dataTitle: ''
  },
  {
    value: "errorNoGpsAntenna",
    checked: false,
    dataTitle: ''
  },
  {
    value: "drivingWithoutCANConnection",
    checked: false,
    dataTitle: ''
  },
  {
    value: "blackoutLighting",
    checked: false,
    dataTitle: ''
  },
  {
    value: "busDoorOpen",
    checked: false,
    dataTitle: ''
  },
  {
    value: "propulsionReady",
    checked: false,
    dataTitle: ''
  },
  {
    value: "busDoorEnable",
    checked: false,
    dataTitle: ''
  },
  {
    value: "lowSOCLimpHome",
    checked: false,
    dataTitle: ''
  },
  {
    value: "doorStatus",
    checked: false,
    dataTitle: ''
  },
  {
    value: "charging",
    checked: false,
    dataTitle: ''
  },
  {
    value: "ptoMode",
    checked: false,
    dataTitle: ''
  },
  {
    value: "ptoModeAL1",
    checked: false,
    dataTitle: ''
  },
  {
    value: "ptoModeAL2",
    checked: false,
    dataTitle: ''
  },
  {
    value: "ptoModeAWD1",
    checked: false,
    dataTitle: ''
  },
  {
    value: "ptoModeAWD2",
    checked: false,
    dataTitle: ''
  },
  {
    value: "ptoModeED1",
    checked: false,
    dataTitle: ''
  },
  {
    value: "ptoModeED2",
    checked: false,
    dataTitle: ''
  },
  {
    value: "ptoModeEG1",
    checked: false,
    dataTitle: ''
  },
  {
    value: "ptoModeEG2",
    checked: false,
    dataTitle: ''
  },
  {
    value: "ptoModeEG3",
    checked: false,
    dataTitle: ''
  },
  {
    value: "ptoModeEK1",
    checked: false,
    dataTitle: ''
  },
  {
    value: "ptoModeEK2",
    checked: false,
    dataTitle: ''
  },
  {
    value: "hazardlight",
    checked: false,
    dataTitle: ''
  },
  {
    value: "chargingDetails",
    checked: false,
    dataTitle: ''
  }
]

const posSchemaVariables = [
  {
    value: "version",
    checked: false,
    dataTitle: ''
  },
  {
    value: "externalEquipmentReference",
    checked: false,
    dataTitle: ''
  },
  {
    value: "externalDriverIdentificationReference1",
    checked: false,
    dataTitle: ''
  },
  {
    value: "externalDriverIdentificationReference2",
    checked: false,
    dataTitle: ''
  },
  {
    value: "gpsTimestamp",
    checked: false,
    dataTitle: ''
  },
  {
    value: "gpsHeading",
    checked: false,
    dataTitle: ''
  },
  {
    value: "gpsSpeed",
    checked: false,
    dataTitle: ''
  },
  {
    value: "gpsLatitude",
    checked: false,
    dataTitle: ''
  },
  {
    value: "gpsLongitude",
    checked: false,
    dataTitle: ''
  },
  {
    value: "gpsAltitude",
    checked: false,
    dataTitle: ''
  },
  {
    value: "hdop",
    checked: false,
    dataTitle: ''
  },
  {
    value: "correlationId",
    checked: false,
    dataTitle: ''
  },
  {
    value: "vehicleStopped",
    checked: false,
    dataTitle: ''
  },
  {
    value: "triggerReasons",
    checked: false,
    dataTitle: ''
  },
  {
    value: "processingLog",
    type: 'list',
    checked: false,
    dataTitle: 'list'
  },
  {
    value: "countryCode",
    checked: false,
    dataTitle: ''
  },
  {
    value: "origin",
    checked: false,
    dataTitle: ''
  }]

export {
  csSchemaVariables,
  fiSchemaVariables,
  posSchemaVariables
};