import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import Toast from '../../../components/Toast'
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { getUnitMessages, getRtcLogsContents, requestOpenCloseSshPort, requestRtcLogs, resetError, createNotification } from '../../../redux/store/rtcUnitLog';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc'
import './rtc-unit-log.scss';
import RTCLogPage from './rtc-unit-log-page';
import { UserContext } from '../../../appContext';

dayjs.extend(utcPlugin);

type RTCLogPropTypes = {
}

// eslint-disable-next-line no-empty-pattern
const RTCLogPageWrapper = ({ }: RTCLogPropTypes) => {
  const dispatch = useAppDispatch();
  const { selectedVehicle, selectedCustomer } = useAppSelector(state => state.universalSearch);

  const { unitMessages, isLoading, hasError, errorMessage, isNotified, telematicsUnitEquipmentInfo, rtcLogContents, areContentsLoading, logContentsMessageId, searchEquipmentReference } = useAppSelector(state => state.rtcUnitLog);
  const [showContents, setShowContents] = useState<boolean>(false);
  const [messageId, setMessageId] = useState<string>("");
  const [downloadUrl, setDownloadUrl] = useState<string>("");
  const [isOpenSsHRequest, setIsOpenSshRequest] = useState<boolean>(false);
  const [showSSHConfirm, setShowSSHConfirm] = useState<boolean>(false);
  const [showRtcLogConfirm, setShowRtcLogConfirm] = useState<boolean>(false);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);
  const loggedInProfileInfo  = useContext<ProfileInformationType>(UserContext);

  useEffect(() => {
    if (errorMessage !== '') {
      setTimeout(() => {
        dispatch(resetError());
      }, 5000);
    }
  }, [hasError])

  useEffect(() => {
    if(searchEquipmentReference !== selectedVehicle.externalEquipmentReference){
      dispatch(getUnitMessages(selectedVehicle.externalEquipmentReference));
      setCurrentPageIndex(1);
     }
  }, [selectedVehicle]);

  useEffect(() => {
    if (messageId !== '') {
      const rtcLog = rtcLogContents.replace(/\n/g, '\r\n');
      var blob = new Blob([rtcLog], { type: 'text/plain' });
      var url = window.URL || window.webkitURL;
      const rtcLogDownloadUrl = url.createObjectURL(blob);
      setDownloadUrl(rtcLogDownloadUrl);
      setShowContents(true);
    }
  }, [logContentsMessageId])

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPageIndex - 1) * 20;
    const lastPageIndex = firstPageIndex + 20;
    return unitMessages.slice(firstPageIndex, lastPageIndex);
  }, [currentPageIndex, unitMessages]);

  const onDismissToast = () => {
    dispatch(resetError());
  }
  const onEmailNotificationCheckChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      dispatch(createNotification(selectedVehicle.externalEquipmentReference, selectedVehicle.identification));
    }
  }

  const onLoadContents = (logsMessageId: string) => {
    if (logContentsMessageId !== logsMessageId) {
      dispatch(getRtcLogsContents(logsMessageId));
      setMessageId(logsMessageId);
    } else {
      setShowContents(true);
    }
  }

  const onRequestRtcLog = () => {
    dispatch(requestRtcLogs(selectedVehicle.externalEquipmentReference, selectedVehicle.identification));
    setShowRtcLogConfirm(false);
  }
  const onOpenCloseSSHPortClick = (openFlag: boolean) => {
    setIsOpenSshRequest(openFlag);
    setShowSSHConfirm(true);
  }
  const onConfirmToggleSshPort = () => {
    dispatch(requestOpenCloseSshPort(selectedVehicle.externalEquipmentReference, isOpenSsHRequest, selectedVehicle.identification));
    setShowSSHConfirm(false);
  }

  const onRefresh = () => {
    dispatch(getUnitMessages(selectedVehicle.externalEquipmentReference));
  }

  return <>
 { ((selectedCustomer && selectedCustomer?.scaniaLegalDocumentationSigned && selectedCustomer?.scaniaRead) || ((selectedCustomer === undefined ||selectedCustomer === null) && (loggedInProfileInfo?.role === 'Admin' || loggedInProfileInfo?.role === 'GTS'))) &&
    <div className="panel active" >
      <RTCLogPage
        unitMessages={currentTableData}
        isLoading={isLoading}
        hasError={hasError}
        errorMessage={errorMessage}
        isNotified={isNotified}
        telematicsUnitEquipmentInfo={telematicsUnitEquipmentInfo}
        rtcLogContents={rtcLogContents}
        areContentsLoading={areContentsLoading}
        showContents={showContents}
        downloadUrl={downloadUrl}
        currentLogContentsMessageId={messageId}
        showSSHConfirm={showSSHConfirm}
        showRtcLogConfirm={showRtcLogConfirm}
        onShowSSHConfirm={(openFlag) => onOpenCloseSSHPortClick(openFlag)}
        onHideSSHConfirm={() => setShowSSHConfirm(false)}
        onShowRTCLogConfirm={() => setShowRtcLogConfirm(true)}
        onHideRTCLogConfirm={() => setShowRtcLogConfirm(false)}
        onRequestRTCLogs={onRequestRtcLog}
        onOpenCloseSSH={onConfirmToggleSshPort}
        onRequestLogMessageContents={onLoadContents}
        onHideContents={() => setShowContents(false)}
        onEmailNotificationCheckChange={onEmailNotificationCheckChange}
        currentPageIndex={currentPageIndex}
        onPageIndexChange={setCurrentPageIndex}
        totalRowsCount={unitMessages.length}
        onRefresh={onRefresh} />

      {hasError && errorMessage !== '' && <Toast type="error" headline={errorMessage} onDismiss={onDismissToast} />}
    </div>
}
  </>
}
export default RTCLogPageWrapper
