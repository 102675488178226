import { t } from 'i18next';
import './EquipmentTour.scss'

const generateTourStepsByRole = (userRole: string): any => {

    const equipmentOverviewLabel: string = t("TS_core:EquipmentOverview");
    const communicationLabel: string = t("TS_core:Communication");
    const csdLabel: string = t("TS_core:ConnectedServicesDeviations");
    const equipmentSearchBatch: string =t("TS_core:EquipmentBatchSearch");
    const feedbackLabel: string =t("TS_core:SendFeedback");
    const equipmentOverviewDesciption: string = t("TS_core:W_EqipmentOverview");
    const communicationDesciption: string = t("TS_core:W_Communication");
    const csdDesciption: string = t("TS_core:W_CSD");
    const equipmentSearchBatchDesciption: string = t("TS_core:W_EquiBatchSearch");
    const feedbackDescription: string = t("TS_core:W_Feedback");

    if(userRole === "Admin" || userRole === "GTS")
    {
        return([
            {
                title:equipmentOverviewLabel,
                target:"#equipOverview",
                content:<p className ='pageTour'>{equipmentOverviewDesciption}</p>,
                placement: "right-start" as const,
                disableBeacon: true
            },
            {
                title:communicationLabel,
                target:"#communication_Tour_Id",
                content:<p className ='pageTour'>{communicationDesciption}</p>,
                disableBeacon: true
            },
            {
                title:csdLabel,
                target:"#connectedServicesDeviations_Tour_Id",
                content:<p className ='pageTour'>{csdDesciption}</p>,
                disableBeacon: true
            },
            {
                title:equipmentSearchBatch,
                target:"#EquipmentSatchSearch_Tout_id",
                content:<p className ='pageTour'>{equipmentSearchBatchDesciption}</p>,
                disableBeacon: true
            },
            {
                title:feedbackLabel,
                target:'#surveySliderBtn',
                content:<p className ='pageTour'>{feedbackDescription}</p>
            }
        ])
    }
    else if(userRole === "FQ"){
        return([
            {
                title:equipmentOverviewLabel,
                target:"#equipOverview",
                content:<p className ='pageTour'>{equipmentOverviewDesciption}</p>,
                placement: "right-start" as const,
                disableBeacon: true
            },
            {
                title:communicationLabel,
                target:"#communication_Tour_Id",
                content:<p className ='pageTour'>{communicationDesciption}</p>,
                disableBeacon: true
            },
            {
                title:equipmentSearchBatch,
                target:"#EquipmentSatchSearch_Tout_id",
                content:<p className ='pageTour'>{equipmentSearchBatchDesciption}</p>,
                disableBeacon: true
            },
            {
                title:feedbackLabel,
                target:'#surveySliderBtn',
                content:<p className ='pageTour'>{feedbackDescription}</p>
            }
        ])
    }
    else if(userRole === "LoTH" || userRole === "Basic"){
        return([
            {
                title:equipmentOverviewLabel,
                target:"#equipOverview",
                content:<p className ='pageTour'>{equipmentOverviewDesciption}</p>,
                placement: "right-start" as const,
                disableBeacon: true
            },
            {
                title:communicationLabel,
                target:"#communication_Tour_Id",
                content:<p className ='pageTour'>{communicationDesciption}</p>,
                disableBeacon: true
            },
            {
                title:feedbackLabel,
                target:'#surveySliderBtn',
                content:<p className ='pageTour'>{feedbackDescription}</p>
            }
        ])
    }
    else{ //BasicLimited & Assistance
        return([
            {
                title:equipmentOverviewLabel,
                target:"#equipOverview",
                content:<p className ='pageTour'>{equipmentOverviewDesciption}</p>,
                placement: "right-start" as const,
                disableBeacon: true
            },
            {
                title:feedbackLabel,
                target:'#surveySliderBtn',
                content:<p className ='pageTour'>{feedbackDescription}</p>
            }
        ])
    }
}

export default generateTourStepsByRole;

export {
    generateTourStepsByRole
}