export const LOW: number = 59;
export const MEDIUM: number = 60;
export const HIGH: number = 1800;

export const getDelay = (delay: number) => {
  if (delay >= HIGH) {
    return HIGH;
  }
  else if (delay >= MEDIUM) {
    return MEDIUM;
  }

  return LOW;
}