import React, { useState } from "react"
import InfoIconWithLabel from "../../InfoIconWithLabel"
import { useLocalize } from "../../../hooks/useLocalize"
import CsdDataTable from "../CsdDataTable/CsdDataTable"
import CsdDetails from "../CsdDetails/CsdDetails"

interface CSDMainWrapperProps {
  csdList: Array<CsdType>
  selectedVehicle: VehicleType
  isLoading: boolean
  csdDefinition: CsdDefinitionType | null
  isCsdDefLoading: boolean
  onGridReloadAction: () => void
}

const CSDMainWrapper = ({ selectedVehicle, csdList, csdDefinition, isLoading, isCsdDefLoading,onGridReloadAction }: CSDMainWrapperProps) => {
  const { t } = useLocalize();
  const [selectedCsd, setSelectedCsd] = useState<CsdType | null>(null);

  const onSelectCsd = (csd: any) => {
    setSelectedCsd(csd);
  }

  const onDetailsClose = () => {
    setSelectedCsd(null);
  }
  return <>
    <InfoIconWithLabel labelText={t("TS_core:AllTimeIsUTCTime")} />
    <div className="panel-wrapper">
      <div className="grid-panel">
        <CsdDataTable
          title={t("TS_core:CsdsForX", [selectedVehicle.alias ? selectedVehicle.alias : (selectedVehicle.chassisNumber ? selectedVehicle.chassisNumber : selectedVehicle.identification)])}
          tableData={csdList}
          isLoading={isLoading}
          onSelectCsd={onSelectCsd}
          onGridReloadAction={onGridReloadAction}
        />
      </div>
      {selectedCsd && <div className="csd-details-panel">
        <CsdDetails onClose={onDetailsClose} csd={selectedCsd} csdDefinition={csdDefinition} selectedVehicle={selectedVehicle} isCsdDefLoading={isCsdDefLoading} />
      </div>}
    </div>
  </>
}

export default CSDMainWrapper