
import { TFunction } from "i18next";
import _ from "lodash";
import alasql from "alasql";
import { exportExcelUtcFormatFromString , utcFormatFromString } from "./timeFormatter";


const assignValuesToObj = (msgObj: any, messageType: CHMessageTypeEnum): any => {
  //initialize    
  let newMsgObject: any = {
    version: '',
    externalDriverIdentificationReference1: '',
    externalEquipmentReference: '',
    messageTimestamp: '',
    correlationId: '',
    sequenceNumber: 0,
    triggerReasons: [''],
    ProcessingLog: '',
  };
  //dynamically assign variable keys and initialize
  Object.keys(msgObj.variables).forEach(key => {
    newMsgObject[key] = '';
  })

  //dynamically assign variable values
  Object.keys(newMsgObject).forEach(key => {
    newMsgObject[key] = msgObj[key] || msgObj.variables[key]?.value
  })
  // console.log(msgObj);
  // console.log(newMsgObject)
  if (messageType === 'FunctionIndication')
    newMsgObject.messageType = 'Function indication message'
  else
    newMsgObject.messageType = 'Current status message'

  newMsgObject.messageTimestamp = utcFormatFromString(msgObj.messageTimestamp);
  newMsgObject.timeSaved = utcFormatFromString(msgObj?.ProcessingLog?.find((item: any) => item.processStep === "ARRIVEDMP")?.timestamp);
  newMsgObject.ProcessingLog =  newMsgObject.ProcessingLog.map((item: any)=> `${item.processStep} : ${item.timestamp}`);

  return newMsgObject;
}

const parseDataForExcelExport = (dataToParse: CommHistoryExcelExportDataType, selectedMessageTypes: string[], unitType: string): Array<any> => {

  let exportReadyData: Array<any> = [];

  if (selectedMessageTypes.includes('Position'))
    _.forEach(dataToParse.position, (positionMessage:PositionMsgType) => {

      let newPositionObject: any = {
        messageType: 'Position',
        version: '',
        externalEquipmentReference: '',
        messageTimestamp: '',
        gpsTimestamp: '',
        gpsHeading: 0,
        gpsSpeed: 0,
        gpsLatitude: 0,
        gpsLongitude: 0,
        gpsAltitude: 0,
        hdop: 0,
        correlationId: '',
        vehicleStopped: false,
        triggerReasons: [''],
        ProcessingLog: '',
        timeSaved: '',
        convertedGpsSpeed:''
      };

      newPositionObject.messageType = 'Position message'
      newPositionObject.version = positionMessage.version;
      newPositionObject.externalEquipmentReference = positionMessage.externalEquipmentReference;
      newPositionObject.gpsTimestamp = utcFormatFromString(positionMessage.gpsTimestamp);
      newPositionObject.messageTimestamp = utcFormatFromString(positionMessage.messageTimestamp);
      const timeSavedValue = positionMessage?.ProcessingLog?.find((item: any) => item.processStep === "ARRIVEDMP")?.timeStamp || '';
      newPositionObject.timeSaved = timeSavedValue ? utcFormatFromString(timeSavedValue): '';
      newPositionObject.gpsHeading = positionMessage.gpsHeading;
      newPositionObject.gpsSpeed =positionMessage.convertedGpsSpeed;
      newPositionObject.gpsLatitude = positionMessage.gpsLatitude;
      newPositionObject.gpsLongitude = positionMessage.gpsLongitude;
      newPositionObject.gpsAltitude = positionMessage.gpsAltitude;
      newPositionObject.hdop = positionMessage.hdop;
      newPositionObject.correlationId = positionMessage.correlationId;
      newPositionObject.vehicleStopped = positionMessage.vehicleStopped;
      newPositionObject.triggerReasons = positionMessage.triggerReasons;
      newPositionObject.ProcessingLog = positionMessage?.ProcessingLog?.map(item=> `${item.processStep} : ${item.timeStamp}`) || '';
      exportReadyData.push(newPositionObject);
    });

  if (selectedMessageTypes.includes('FunctionIndication'))
    _.forEach(dataToParse.functionIndication, (fiMessage:FunctionIndicationMsgType) => {
      exportReadyData.push(assignValuesToObj(fiMessage, 'FunctionIndication'));
    });

  if (selectedMessageTypes.includes('CurrentStatus'))
    _.forEach(dataToParse.currentstatus, (csMessage:CurrentStatusMsgType) => {

      let {odometer, odometerICL, odometerDistance, tachoSpeed, ...rest} = csMessage.variables;

      if(odometer){
        odometer = {...odometer,  value: csMessage.convertedOdometer }
      }
      if(odometerICL){
        odometerICL = {...odometerICL,  value: csMessage.convertedOdometerICL }
      }
      if(odometerDistance){
        odometerDistance = {...odometerDistance,  value: csMessage.convertedOdometerDistance }
      }
      if(tachoSpeed){
        tachoSpeed = {...tachoSpeed,  value: csMessage.convertedTachoSpeed }
      }
      const updatedCsList = {...csMessage, variables: {odometer, odometerICL, odometerDistance, tachoSpeed, ...rest}};

      exportReadyData.push(assignValuesToObj(updatedCsList, 'CurrentStatus'));
    });

  return exportReadyData;
}

const mapColumns = (t: TFunction, userRole: string, selectedMessageTypes:  string[], exportReadyData: Array<any>) => {
  let columns = [];


  columns.push({ columnid: 'messageType', title: t("TS_core:MessageType"), sortable: true });
  if (selectedMessageTypes.includes('Position')) columns.push({ columnid: 'gpsTimestamp', title: t("TS_core:PositionTime"), sortable: true });
  columns.push({ columnid: 'messageTimestamp', title: t("TS_core:MessageTime"), sortable: true });
  columns.push({ columnid: 'timeSaved', title: t("TS_core:TimeSaved"), sortable: true });


  if (selectedMessageTypes.includes('Position')) {
    if (userRole !== 'BasicLimited' && userRole !== 'Basic' && userRole !== 'Assistance') {
      columns.push({ columnid: 'gpsLatitude', title: t("TS_core:Latitude"), sortable: true });
      columns.push({ columnid: 'gpsLongitude', title: t("TS_core:Longitude"), sortable: true });
    }
    if (userRole === 'GTS' || userRole === 'Admin' || userRole === 'FQ') {
      columns.push({ columnid: 'version', title: t("TS_core:Version"), sortable: true });
      columns.push({ columnid: 'externalEquipmentReference', title: t("TS_core:ExternalEquipmentReference"), sortable: true });
      columns.push({ columnid: 'gpsHeading', title: t("TS_temp:GPSHeading"), sortable: true });
      columns.push({ columnid: 'gpsSpeed', title: t("TS_core:GPSSpeed"), sortable: true });
      columns.push({ columnid: 'gpsAltitude', title: t("TS_core:GPSAltitude"), sortable: true });
      columns.push({ columnid: 'hdop', title: t("TS_temp:HDOP"), sortable: true });
      columns.push({ columnid: 'correlationId', title: t("TS_core:CorrelationId"), sortable: false });
      columns.push({ columnid: 'vehicleStopped', title: t("TS_core:VehicleStopped"), sortable: false });
      columns.push({ columnid: 'triggerReasons', title: t("TS_core:TriggerReasons"), sortable: true });
      columns.push({ columnid: 'ProcessingLog', title: t("TS_temp:ProcessingLog"), sortable: true });
    }
  }

  if (selectedMessageTypes.includes('FunctionIndication')) {
    columns.push({ columnid: 'ignition', title: t("TS_core:Ignition"), sortable: true });
    if (userRole === 'GTS' || userRole === 'Admin' || userRole === 'FQ') {
      _.forEach(exportReadyData, (dataRecord: any) => {
        if (dataRecord.messageType === 'Function indication message') {
          Object.keys(dataRecord).forEach(key => {
            columns.push({ columnid: key, title: t(key), sortable: true });
          })
          return false;
        }
      });
    }
  }

  if (selectedMessageTypes.includes('CurrentStatus')) {
    columns.push({ columnid: 'tachoSpeed', title: t("TS_temp:TachoSpeed"), sortable: true });
    columns.push({ columnid: 'odometerICL', title: t("TS_core:Odometer")+"ICL", sortable: true });
    //Removing all users odometer accesible column from expoert excel
    // columns.push({ columnid: 'odometer', title: t("TS_core:Odometer"), sortable: true });

    if (userRole === 'GTS' || userRole === 'Admin'|| userRole === 'FQ') {
      _.forEach(exportReadyData, (dataRecord: any) => {
        if (dataRecord.messageType === 'Current status message') {
          Object.keys(dataRecord).forEach(key => {
            columns.push({ columnid: key, title: t(key), sortable: true });
          })
          return false;
        }
      });
    }
  }

  //remove duplicate columns when multiple message types are selected
  var result = columns.reduce((unique: {
    columnid: string;
    title: string;
    sortable: boolean;
  }[], o) => {
    if (!unique.some((obj: { columnid: string, title: string, sortable: boolean }) => obj.columnid === o.columnid)) {
      unique.push(o);
    }
    return unique;
  }, []);

  return result;
}


const getColumnsConfig = (t: TFunction, userRole: string, selectedMessageTypes: string[], allDataToExport: Array<any>) => {
  return {
    headers: true,
    column: {
      style: 'font-size:16px'
    },
    columns: mapColumns(t, userRole, selectedMessageTypes, allDataToExport)
  };
}

const exportCHResultsToExcel = (exportReadyData: Array<any>, selectedMessageTypes:  string[], t: TFunction, userRole: string, fromDate?: Date|string, toDate?: Date|string, vin?: string) => {

  //sort data by messageTimestamp before exporting to excel
  if (exportReadyData && exportReadyData.length > 0) {
    let sortedResults = _.sortBy(exportReadyData, "messageTimestamp").reverse();//in descending order 
        exportReadyData = sortedResults as Array<any>;
  }

  const config = getColumnsConfig(t, userRole, selectedMessageTypes, exportReadyData);
  const timestamp = exportExcelUtcFormatFromString(fromDate) + '_to_' + exportExcelUtcFormatFromString(toDate)
  const filename = ["Communication_History", vin, timestamp,].join('_') + '.xls';
  return alasql.promise('SELECT * INTO XLS("' + filename + '", ?) FROM ?', [config, exportReadyData]);
}
export {
  exportCHResultsToExcel,
  parseDataForExcelExport,
  getColumnsConfig
}