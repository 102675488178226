
import { TFunction } from "i18next";
import _ from "lodash";
import { HIGH, MEDIUM, getDelay } from "../chartHelper";
import moment from "moment";
import "moment-duration-format";

export const getAverageMessageDelay = (messages: any, t: TFunction) => {
  
  var delayOnBoard = _.map(messages, function (m) {
    var duration = moment.duration(moment(m.timeMessage).diff(moment(m.timePosition)));
    return duration.asSeconds();
  });
  var delayOffBoard = _.map(messages, function (m) {
    var duration = moment.duration(moment(m.timeSaved).diff(moment(m.timeMessage)));
    return duration.asSeconds();
  });
  var delayTotal = _.map(messages, function (m) {
    var duration = moment.duration(moment(m.timeSaved ? m.timeSaved : m.timeMessage).diff(moment((m.timePosition))));
    return duration.asSeconds();
  });

  const averageDelayOnBoard = _.sum(delayOnBoard)/messages.length;
  const averageDelayOffBoard = _.sum(delayOffBoard)/messages.length;
  const averageDelayTotal = _.sum(delayTotal)/messages.length;

  let icon = '/assets/icons/green-dot.svg';
  if (getDelay(averageDelayTotal) === HIGH) {
    icon = '/assets/icons/red-dot.svg';
  } else if (getDelay(averageDelayTotal) === MEDIUM) {
    icon = '/assets/icons/orange-dot.svg';
  } else {
    icon = '/assets/icons/green-dot.svg';
  }

  return {
    averageDelayOnBoard: averageDelayOnBoard,
    averageDelayOffBoard: averageDelayOffBoard,
    averageDelayTotal: averageDelayTotal,
    icon: icon,
    toolTip: t("TS_core:TimeCostOnEquipment") + ': '
      +  moment.duration(averageDelayOnBoard, 'seconds').format('d[d] h[h] m[m] s[s]')
      + ', ' + t("TS_core:TimeCostOnOffBoard") + ': '
      + moment.duration(averageDelayOffBoard, 'seconds').format('d[d] h[h] m[m] s[s]')
  };
}
export const getArrivedMpTimestampInUtc = (message: any) => {
  var processingLogPropertyKey: any = _.findKey(message, function (value, key) { return key.toLowerCase() === 'processinglog'; });
  var arrivedProperty = _.find(message[processingLogPropertyKey], function (x) { return x.processStep === 'ARRIVEDMP'; });
  var timestampPropertyKey: any = _.findKey(arrivedProperty, function (value, key) { return key.toLowerCase() === 'timestamp'; });
  return arrivedProperty ? moment.utc(arrivedProperty[timestampPropertyKey]).format('YYYY-MM-DDTHH:mm:ssZ') : null;
}

export const prepareChartData = (messages: any, startTime: any, endTime: any, t: TFunction) => {
  // Prepare data
  var data = [];
  messages = _.map(messages, (message) => {
    var newMessage: any = { messageType: "position", timeSaved: null };
    newMessage.timeMessage = message.messageTimestamp;
    newMessage.timePosition = message.gpsTimestamp;
    newMessage.timeSaved = getArrivedMpTimestampInUtc(message);
    newMessage.latitude = message.gpsLatitude;
    newMessage.longitude = message.gpsLongitude;
    newMessage.speed = message.gpsSpeed;
    return newMessage
  })

  // // Refactor when new and old communication history doesn't run in parallell anymore
  // var filteredMessages = _.filter(messages, function (message) {
  //   // Include old communicator messages
  //   if (!message.messageType) return true;
  //   return message.messageType === 'position';
  // });

  messages = _.sortBy(messages, ['timeMessage']);
  var messagesGroupByTimeMessageInHour = _.groupBy(messages, function (m) {
    return moment.utc(m.timeMessage).endOf('hour');
  });

  startTime = moment.utc(startTime);
  endTime = moment.utc(endTime);
  var indexTime = startTime.endOf('hour');
  endTime = endTime.endOf('hour');

  while (indexTime <= endTime) {
    var date = indexTime.toDate();
    var messageCount = 0;
    var icon = undefined;
    var toolTip = undefined;
    if (messagesGroupByTimeMessageInHour[indexTime]) {
      messageCount = messagesGroupByTimeMessageInHour[indexTime].length;
      var delay = getAverageMessageDelay(messagesGroupByTimeMessageInHour[indexTime], t);
      icon = delay.icon;
      toolTip = delay.toolTip;
    }
    data.push({
      date: date,
      messageCount: messageCount,
      icon: icon,
      toolTip: toolTip
    });
    indexTime = indexTime.add(1, 'hour');
  }
  return data;
}