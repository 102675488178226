import { createSlice } from '@reduxjs/toolkit'
import { fetchCommunicationStreamData } from '../../adapters/CommunicationHistoryAdapter';
import { unitConvertor } from '../../helpers/unitConverter';
import { RootState } from '.';

const getInitialState = (): CommunicationStateType => {
  const State: CommunicationStateType = {
    isLoading: false,
    hasError: false,
    errorMessage: "",
    statusCode: -1,
    combinnedCommunicationData: [],
    currentStatusList: { data: [], paginationtoken: "" },
    functionIndicationList: { data: [], paginationtoken: "" },
    positionList: { data: [], paginationtoken: "" },
    searchEquipmentReference: "",
    searchInput: undefined,
    searchMessageChecks: { "CurrentStatus": true, "Position": true, "FunctionIndication": true }
  }
  return State;
}

// Slice
const slice = createSlice({
  name: 'communicationHistory',
  initialState: getInitialState(),
  reducers: {
    setStartActionLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    setCompleteActionLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    updateCombinnedCommunicationData: (state, action) => {
      state.combinnedCommunicationData = action.payload;
    },
    updateCurrentstatusMessageData: (state, action) => {
      state.currentStatusList = action.payload;
    },
    updateFunctionIndicationMessageData: (state, action) => {
      state.functionIndicationList = action.payload;
    },
    updatePositionMessageData: (state, action) => {
      state.positionList = action.payload;
    },
    setSearchEquipmentReference: (state, action) => {
      state.searchEquipmentReference = action.payload
    },
    updateSearchInputData: (state, action) => {
      state.searchInput = action.payload;
    },
    updateSearchCheckboxData: (state, action) => {
      state.searchMessageChecks = action.payload;
    },
    updateError: (state, action) => {
      state.hasError = action.payload !== '';
      state.errorMessage = action.payload.description;
      state.isLoading = false;
      state.statusCode = action.payload.statusCode
    },
  },
});
export default slice.reducer

// Actions
const { setStartActionLoader, setCompleteActionLoader, updateCurrentstatusMessageData, updatePositionMessageData, updateFunctionIndicationMessageData, updateCombinnedCommunicationData, updateSearchInputData, setSearchEquipmentReference, updateSearchCheckboxData, updateError } = slice.actions

export const loadCommunicationData = (externalEquipmentReference: string, startTime: string, endTime: string, messageType: string, paginationToken: string, unitType: string) => async (dispatch: any, getState: any) => {
  try {
    let { communicationHistoryStore,communicatorInfo } = getState() as RootState;
    let rtcType = communicatorInfo.EquipmentInformation.rtcType || "";
    let communicationResponseType = await fetchCommunicationStreamData(externalEquipmentReference, startTime, endTime, messageType, paginationToken,rtcType);
    let communicationDataResponse = communicationResponseType.data;
    let speedUnit = rtcType.toLowerCase() === 'c400' ? "km/h" : 'm/s';
    let odometerUnit = rtcType.toLowerCase() === 'c400' ? "km" : 'metre';
    
    if (messageType.toLocaleLowerCase() === 'currentstatus') {
      communicationDataResponse.data = communicationDataResponse.data.map(statusMessages => {

        let odometerValue;
        //Removing ordometer calculation logic from Communication history
        // if (statusMessages.variables.odometerICL) {
        //   odometerValue = statusMessages.variables.odometerICL.state === 'NOT_AVAILABLE' ? statusMessages.variables.odometer.value : statusMessages.variables.odometerICL.value;
        // }
        if (statusMessages.variables.odometer) {
          odometerValue = statusMessages.variables.odometer.value;
        }
        return {
          ...statusMessages,
          messageType: messageType,
          timeSaved: statusMessages?.ProcessingLog?.find((item: any) => item.processStep === "ARRIVEDMP")?.timestamp,
          convertedOdometer: odometerValue ? unitConvertor(odometerValue, odometerUnit, unitType) : '',
          convertedTachoSpeed: statusMessages.variables?.tachoSpeed?.value ? unitConvertor(statusMessages.variables?.tachoSpeed?.value, speedUnit, unitType) : ((unitType === "Metric") ? '0 km/h': '0 mph'),
          convertedOdometerICL: statusMessages.variables?.odometerICL?.value ? unitConvertor(statusMessages.variables?.odometerICL?.value, odometerUnit, unitType) : '',
          convertedOdometerDistance: statusMessages.variables?.odometerDistance?.value ? unitConvertor(statusMessages.variables?.odometerDistance?.value, odometerUnit, unitType) : ''

        }
      })

      if (paginationToken !== '') {
        let appendingCurrentstatusMessageList: CurrentStatusMsgResponseType = {
          data: communicationHistoryStore.currentStatusList.data.concat(communicationDataResponse.data).sort((a: any, b: any) => a.messageTimestamp < b.messageTimestamp ? 1 : -1),
          paginationtoken: communicationDataResponse.paginationtoken
        }
        dispatch(updateCurrentstatusMessageData(appendingCurrentstatusMessageList));
      } else {
        dispatch(updateCurrentstatusMessageData(communicationDataResponse));
      }

    } else if (messageType.toLocaleLowerCase() === 'position') {
      communicationDataResponse.data = communicationDataResponse.data.map(positionMessages => {
        return {
          ...positionMessages,
          messageType: messageType,
          timeSaved: positionMessages?.ProcessingLog?.find((item: any) => item.processStep === "ARRIVEDMP")?.timeStamp, // timeStamp has Captial "S" in stamp
          convertedGpsSpeed: positionMessages.gpsSpeed ? unitConvertor(positionMessages.gpsSpeed, 'm/s', unitType) : ((unitType === "Metric") ? '0 km/h': '0 mph')
        }
      })
      if (paginationToken !== '') {
        let appendingPositionsMessageList: PositionMsgResponseType = {
          data: communicationHistoryStore.positionList.data.concat(communicationDataResponse.data).sort((a: any, b: any) => a.messageTimestamp < b.messageTimestamp ? 1 : -1),
          paginationtoken: communicationDataResponse.paginationtoken
        }
        dispatch(updatePositionMessageData(appendingPositionsMessageList));
      } else {
        dispatch(updatePositionMessageData(communicationDataResponse));
      }
    } else {
      communicationDataResponse.data = communicationDataResponse.data.map(functionMessages => {
        return {
          ...functionMessages,
          messageType: messageType,
          timeSaved: functionMessages?.ProcessingLog?.find((item: any) => item.processStep === "ARRIVEDMP")?.timestamp
        }
      })

      if (paginationToken !== '') {
        let appendingFunctionIndicationMessageList: FunctionIndicationMsgResponseType = {
          data: communicationHistoryStore.functionIndicationList.data.concat(communicationDataResponse.data).sort((a: any, b: any) => a.messageTimestamp < b.messageTimestamp ? 1 : -1),
          paginationtoken: communicationDataResponse.paginationtoken
        }
        dispatch(updateFunctionIndicationMessageData(appendingFunctionIndicationMessageList));
      } else {
        dispatch(updateFunctionIndicationMessageData(communicationDataResponse));
      }
    }
    // let updatedCurrentstatusMessage = communicationHistoryStore.combinnedCommunicationData.concat(communicationDataResponse.data).sort((a: any, b: any) => a.messageTimestamp < b.messageTimestamp ? 1 : -1);
    // dispatch(updateCombinnedCommunicationData(updatedCurrentstatusMessage));
  } catch (error: any) {
    if(error && error.response && error.response.data){
      dispatch(updateError(error.response.data));  
    }else{
      dispatch(updateError(error));
    }
    return console.error(error);
  }
}
export const combineLoadedData = (selectedMessageTypes : Map<string,boolean>) => async(dispatch: any,getState:Function) => {
  const  { communicationHistoryStore } = getState() as RootState;
  const {currentStatusList,functionIndicationList,positionList}  = communicationHistoryStore;
  let combinedData:any = [];
  
  if(selectedMessageTypes.get("CurrentStatus") && currentStatusList.data){
    combinedData = combinedData.concat(currentStatusList.data)
  }

  if(selectedMessageTypes.get("Position") && positionList.data){
    combinedData = combinedData.concat(positionList.data)
  }
  if(selectedMessageTypes.get("FunctionIndication") && functionIndicationList.data){
    combinedData = combinedData.concat(functionIndicationList.data)
  }
  
  combinedData = combinedData.sort((a: any, b: any) => a.messageTimestamp < b.messageTimestamp ? 1 : -1);
  dispatch(updateCombinnedCommunicationData(combinedData));
}

export const clearCommunicationData = () => async (dispatch: any) => {
  try {
    dispatch(updateCombinnedCommunicationData([]));
    dispatch(updateCurrentstatusMessageData([]));
    dispatch(updatePositionMessageData([]));
    dispatch(updateFunctionIndicationMessageData([]));

  } catch (error: any) {
    dispatch(updateError(error));
    return console.error(error);
  }
}

export const sortCommunicationMessageDataList = (sortedMessageData: Array<any>) => async (dispatch: any) => {
  try {
    dispatch(updateCombinnedCommunicationData(sortedMessageData));
  } catch (error: any) {
    dispatch(updateError(error));
    return console.error(error);
  }
}

export const filterCommunicationMessageDataList = (messageTypes: string[]) => async (dispatch: any, getState: any) => {
  try {
    let { communicationHistoryStore } = getState();
    let filterMessageList: Array<any> = [];
    messageTypes.forEach(messageType => {
      if (messageType.toLocaleLowerCase() === 'position' && communicationHistoryStore.positionList.data?.length > 0) {
        filterMessageList = filterMessageList.concat(communicationHistoryStore.positionList.data);
      }
      else if (messageType.toLocaleLowerCase() === 'functionindication' && communicationHistoryStore.functionIndicationList.data?.length > 0) {
        filterMessageList = filterMessageList.concat(communicationHistoryStore.functionIndicationList.data);
      }
      else if (messageType.toLocaleLowerCase() === 'currentstatus' && communicationHistoryStore.currentStatusList.data?.length > 0) {
        filterMessageList = filterMessageList.concat(communicationHistoryStore.currentStatusList.data);
      }
    })
    let sortedMessageList = filterMessageList.sort((a: any, b: any) => a.messageTimestamp < b.messageTimestamp ? 1 : -1);
    dispatch(updateCombinnedCommunicationData(sortedMessageList));
  } catch (error: any) {
    dispatch(updateError(error.response.data));
    return console.error(error.response.data);
  }
}

export const startActionLoader = () => async (dispatch: any) => {
  try {
    dispatch(setStartActionLoader(true));
  } catch (error: any) {
    return console.error(error);
  }
}

export const completeActionLoader = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(setCompleteActionLoader(false));
  } catch (error: any) {
    return console.error(error);
  }
}

export const setSearchInputsIntoStore = (fromDate: string, toDate: string, messageTypes: string[], externalEquipmentReference: string) => async (dispatch: any) => {
  try {
    dispatch(setSearchEquipmentReference(externalEquipmentReference));
    if (fromDate !== '' && toDate !== '') {
      dispatch(updateSearchInputData({ fromDate: fromDate, toDate: toDate, messageTypes: messageTypes }));
    } else {
      dispatch(updateSearchInputData(undefined));
    }

  } catch (error: any) {
    dispatch(updateError(error.response.data));
    return console.error(error.response.data);
  }
}

export const setSearchCheckboxInputsIntoStore = (messageChecks: IHashMap<boolean>) => async (dispatch: any, getState: any) => {
  try {
    dispatch(updateSearchCheckboxData(messageChecks));
  } catch (error: any) {
    dispatch(updateError(error.response.data));
    return console.error(error.response.data);
  }
}

export const clearCombinedResults = () => async (dispatch: any) => {
  try {    
    dispatch(updateCombinnedCommunicationData([]));
  } catch (error: any) {
    dispatch(updateError(error.response.data));
    return console.error(error.response.data);
  }
}

