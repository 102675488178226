import { AxiosPromise } from 'axios';
import { csttFacadeApi } from '@cd/sdds-common-components-react';


const sendGetCsdsByAttributes = (requestPayload: CsdAttributeRequestPayload): AxiosPromise<CsdType> =>
  csttFacadeApi.post('/csttfacade/docs/v1/csd/attributes', requestPayload);

const sendGetCsdDefinitionById = (definitionId: string, systemName: string): AxiosPromise<CsdDefinitionType> =>
  csttFacadeApi.get(`/csttfacade/docs/v1/csddefinition?id=${definitionId}&systemName=${systemName}`);

export { sendGetCsdsByAttributes, sendGetCsdDefinitionById }