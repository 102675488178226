
import React, { useState, useEffect } from "react";
import { DataTable, DataCell, DataRow, Spinner } from "@cd/sdds-common-components-react";

import { t } from "i18next";
import DateTimeSeperator from "../../CommunicationHistory/DateTimeSeperator";
import _ from "lodash";
import { RefreshIcon } from "@cd/sdds-common-components-react/lib/esm/components/assets";

interface CsdDataTablePropType {
  tableData: Array<CsdType>
  title: string,
  isLoading: boolean
  onSelectCsd: (csd: any) => void
  onGridReloadAction: () => void
}

interface ColumnConfigType {
  columnid: string
  title: string
  sortable: boolean
  freeze?: boolean
  colSpan?: number
  removeable?: boolean
}


export const CsdDataTable = ({ tableData, title, isLoading, onSelectCsd, onGridReloadAction }: CsdDataTablePropType) => {

  const [data, setData] = useState<Array<CsdType>>([]);
  const columnsConfig: Array<ColumnConfigType> = [
    {
      columnid: "systemName",
      title: t("TS_core:SystemName"),
      sortable: false
    },
    {
      columnid: "definitionId",
      title: t("TS_core:Id"),
      sortable: false
    },
    {
      columnid: "creationTime",
      title: t("TS_core:CreatedTime"),
      sortable: false
    },
  ];

  useEffect(() => {
    setData(tableData)
  }, [tableData])

  function onSearch(searchString: string) {
    const searchableColumns = ["systemName", "definitionId", "utcCreationTime"];
    if (searchString.length > 0) {
      if (tableData && tableData.length > 0) {
        const splitedString = searchString.split(",");
        let filteredDataArray = tableData;
        if (splitedString && splitedString.length > 0) {
          splitedString.forEach((searchstr: string) => {
            searchstr = searchstr.trim();
            if (searchstr === "") return [];
            filteredDataArray =  filteredDataArray.filter(a => _.values(_.pick(a, searchableColumns)).some((b) => {
              if (typeof b === 'string') {
                return b.toLocaleLowerCase().includes(searchstr.toLocaleLowerCase());
              } else {
                return b.toString().toLocaleLowerCase() === searchstr.toLocaleLowerCase();
              }
            }
            ));
          });
        }
        setData(filteredDataArray);
        return false;
      }
    } else {
      setData(tableData);
    }
  }

  return <div className="cd-table-wrapper">
    <DataTable
      onSearchChange={onSearch}
      title={title}
      columns={columnsConfig}
      actionBar={<>
        <button className="sdds-table__actionbar-btn" onClick={() => onGridReloadAction()} title={t("TS_core:Refresh")}><RefreshIcon /></button>
      </>}
    >
      {data && data.length > 0 && data.map((row: any) => {
        return <DataRow>
          {columnsConfig?.map((col) => {
            switch (col.columnid) {
              case 'systemName':
                return <DataCell cssClassName="freeze"><span className="sdds-link sdds-link--no-underline" role="button" onKeyDown={() => { }} onClick={() => onSelectCsd(row)}>{row[col.columnid]}</span></DataCell>;
              case 'creationTime':
                return <DataCell cssClassName="freeze"><DateTimeSeperator inputDateTime={row[col.columnid]} /></DataCell>;
              default:
                return <DataCell cssClassName="freeze">{row[col.columnid]}</DataCell>;
            }
          })}
        </DataRow>;
      })}
      {(!data || data.length === 0) && isLoading &&
        <DataRow>
          <DataCell colSpan={3}><Spinner size="md" /></DataCell>
        </DataRow>
      }
      {(!data || data.length === 0) && !isLoading &&
        <DataRow>
          <DataCell colSpan={3}>{t("TS_core:NoDataFound")}</DataCell>
        </DataRow>
      }

    </DataTable>
  </div>;
}

export default CsdDataTable;