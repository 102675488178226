import { TFunction } from "i18next";
import dayjs from 'dayjs';
import alasql from "alasql";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const getColumnsConfig = (t: any) => {
  return {
    headers: true,
    column: {
      style: 'font-size:16px'
    },
    columns: [
      { columnid: 'log', title: t("TS_core:LogMessageType"), sortable: true, filteringEnabled:true },
      { columnid: 'unitId', title: t("TS_core:MobileId"), sortable: true, filteringEnabled:true },
      { columnid: 'typeCode', title: t("TS_core:TypeCode"), sortable: true, filteringEnabled:true },
      { columnid: 'direction', title: t("TS_core:Direction"), sortable: true, filteringEnabled:true },
      { columnid: 'channel', title: t("TS_core:Channel"), sortable: true, filteringEnabled:true },
      { columnid: 'contentType', title: t("TS_core:ContentType"), sortable: true, filteringEnabled:true },
      { columnid: 'numberInSequence', title: t("TS_core:SequenceNo"), sortable: true, filteringEnabled:true },
      { columnid: 'sessionNumber', title: t("TS_core:SessionNr"), sortable: true, filteringEnabled:true },
      { columnid: 'time', title: t("TS_core:MessageSaveTime"), sortable: true, filteringEnabled:true, defaultSortDirection:"desc" },
      { columnid: 'totalInSequence', title: t("TS_core:TotalNrInSequence"), sortable: true, filteringEnabled:true },
      { columnid: 'nackAction', title: t("TS_core:Action"), sortable: true, filteringEnabled:true },
      { columnid: 'nackCause', title: t("TS_core:Cause"), sortable: true, filteringEnabled:true },
      { columnid: 'qualityOfService', title: t("TS_core:QualityOfService"), sortable: true, filteringEnabled:true }
    ]
  };
}

const exportMPLogsToExcel = (transformedData: Array<MPLogRecordType>, t: TFunction, vin:string) => {
  const config = getColumnsConfig(t); 
  const filename = ["MP_communication_log", vin].join('_') + '.xls'; // add vin instead of timestamp
  return alasql.promise('SELECT * INTO XLS("' + filename + '", ?) FROM ?', [config, transformedData]);
}
export {
  exportMPLogsToExcel,
  getColumnsConfig
}