import React,{useState, useEffect} from "react"
import { RefreshIcon } from '@cd/sdds-common-components-react/lib/esm/components/assets';
import ServiceConfigurationAggregateDetail from "./serviceConfigurationAggregateDetail";
import { useTranslation } from 'react-i18next';
import { utcFormatFromString } from "../../helpers/timeFormatter";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { activateEquipmentData } from '../../redux/store/serviceConfiguration';
import { Spinner, useToast, Toast } from '@cd/sdds-common-components-react';
import InfoIconWithLabel from "../InfoIconWithLabel";
import { useNavigate, useLocation } from "react-router-dom";

interface ServiceConfigurationAggregatePropTypes {
    data: EquipmentAggregate
    aggregateDetails: EquipmentAggregateDetail
    refreshAggregateDetails: Function
}

const ServiceConfigurationAggregate = ({ data, aggregateDetails, refreshAggregateDetails }: ServiceConfigurationAggregatePropTypes) => {

  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const dispatch = useAppDispatch();
  const { isAggregateLoading, hasError, errorMessage, isActiveEquipmentLoading   } = useAppSelector<CscCodeStateType>(state => state.cscEquipmentStore);
  const { toastQueue, addToast } = useToast()
  const {pathname} = useLocation()
  const navigate = useNavigate()

  const onRefreshIconClick = () => {
     setToggle(true);
     refreshAggregateDetails();
  }

  const onViewDetailsClick = () => {
    setToggle(!toggle)
    if(!toggle){
      refreshAggregateDetails();
    }
  }

  /*show service activation details by default based on url*/
  useEffect(() => {    
    if(pathname.includes('viewDetails')){      
      setToggle(true);
    }  
  }, [pathname]);

  useEffect(() => {
    if(pathname.includes('viewDetails') && !toggle){
      let pathSegments = pathname.split("/")
      pathSegments.pop();
      let navUrl = pathSegments.join("/")
      navigate(navUrl)
    }

    if(!pathname.includes('viewDetails') && toggle){
      navigate(`${pathname}/viewDetails`);
    }
  }, [toggle]);


 useEffect(() => {
  if(isActiveEquipmentLoading){
    addToast({
      title: t(""), text: t("TS_core:ResendCscActivationSuccess"),
      type: "success",
    })
   }else if (!isActiveEquipmentLoading && (hasError && errorMessage !== '')) {
    addToast({
      title: t("TS_core:UnExpectedErrorTitle"), text: t("TS_core:ResendCscActivationError"),
      type: "error"
    })
  }
 }, [hasError, errorMessage,isActiveEquipmentLoading]);

  const onResendconfigurationClick=() => {
       dispatch(activateEquipmentData(data.externalEquipmentReference));
  }

  return(
    <>
    <div className='sdds-row'><InfoIconWithLabel labelText={t("TS_core:AllTimeIsUTCTime")} /></div>
    <div className="sdds-row sdds-u-mt2">
      <div className="sdds-col-md-3">
        <strong>{t("TS_core:ConfigurationStatus")} </strong>
        <span>{data.status!== null ? data.status: t("TS_core:Unknown") } <button className='sdds-btn sdds-btn-ghost refresh-btn' title={`${t("TS_core:FetchCommunicatorOnlineStatus")}`} onClick={() => { onRefreshIconClick() }}><RefreshIcon /></button></span>
      </div>
      <div className="sdds-col-md-4">
        <strong>{t("TS_core:UpdatedAt")} </strong>
        <span> {utcFormatFromString(data.timeModify)}</span>
      </div>
      <div className="sdds-col-md-5">
        <button className='sdds-btn sdds-btn-secondary' onClick={onViewDetailsClick}>{toggle?t("TS_core:HideDetails"):t("TS_core:ViewDetails")}</button>
        <button className='sdds-btn sdds-btn-primary' onClick={() => { onResendconfigurationClick() }}>{t("TS_core:ResendConfiguration")}</button>  
      </div>
      {toggle && 
      <>{isAggregateLoading && <div className='loader'><Spinner size="md" /></div> }
      {!isAggregateLoading && <ServiceConfigurationAggregateDetail Data={aggregateDetails} /> }
      <Toast toastQueue={toastQueue} /></> }
    </div>
      
    </>
  )
}

export default ServiceConfigurationAggregate;