import { useTranslation } from "react-i18next";

const formattingRegex = /(\{\d+\})/gim;

export const useLocalize = () => {
  const { t } = useTranslation();

  const newTFunction = (key: string, values?: Array<string>) => {
    let content = t(key);
    if(values && values.length > 0){
      const matches = content.match(formattingRegex);
      if(matches && matches.length > 0){
        matches.forEach((template: any,tempIndex: number)=>{
          content = content.replace(template,values[tempIndex])
        });
      }
    }
    return content;
  }
  return { t: newTFunction }
}