interface ToastPropsType {
  type: "success" | "info" | "error" | "warning"
  headline: string,
  onDismiss?: () => void
}


const Toast = ({ type, headline,onDismiss }: ToastPropsType) => {
  return <div className={`sdds-toast sdds-toast-${type}`}>
    <div className="sdds-toast-icon">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" />
    </div>
    <div className="sdds-toast-content">
      <div className="sdds-toast-header">
        <span className="sdds-toast-headline">{headline}</span>
        <span role="button" className='sdds-toast-dismiss' onClick={onDismiss} onKeyUp={onDismiss} ></span>
      </div>
    </div>
  </div>
}

export default Toast