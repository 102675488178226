
import { TFunction } from "i18next";
import dayjs from 'dayjs';
import alasql from "alasql";

const getColumnsConfig = (t: TFunction) => {
  return {
    headers: true,
    column: {
      style: 'font-size:16px'
    },
    columns: [
      { columnid: 'code', title: t("TS_core:CscCode"), sortable: true }, 
      { columnid: 'description', title: t("TS_core:Description"), sortable: true },
      { columnid: 'type', title: t("TS_core:Type"), sortable: true },
      { columnid: 'numValue', title: t("TS_core:Value"), sortable: true }
    ]
  };
}

const exportCscCodeResultsToExcel = (cscData: Array<CscCodeResponseType>, t: TFunction) => {
  const config = getColumnsConfig(t);
  const timestamp = dayjs.utc().format('YYYYMMDDHHmm')
  const filename = ["Equipment_Csc_Code_Info", timestamp,].join('_') + '_UTC.xls';
  return alasql.promise('SELECT * INTO XLS("' + filename + '", ?) FROM ?', [config, cscData]);
}

const editableCscCodes = {
  sliders: [171, 186],
  dropdowns: [218],
}

const isSliderEditableCodeType = (csc: number): boolean=> {
  return editableCscCodes.sliders.includes(csc);
}

const isDropDownsEditableCodeType = (csc: number): boolean => { 
  return editableCscCodes.dropdowns.includes(csc);
}

const cscDropDownDataList=(t: TFunction)=>{
  return {
   options: [
   {key:'0', value:'Scania PRT + later'}, 
   {key:'1', value:'Scania 4-series'}, 
   {key:'2', value:'Fms v.01'},
   {key:'3', value:'Fms v.02 + later'}
  ]};
 }

export {
  exportCscCodeResultsToExcel,
  getColumnsConfig,
  isSliderEditableCodeType,
  isDropDownsEditableCodeType,
  cscDropDownDataList
}