import React, { memo, useEffect, useRef } from 'react'
import { debounce } from 'lodash';

interface SliderProps {
  id:any;
  step?: number;
  minValue: number;
  maxValue: number;
  initialValue: number;
  onSliderValueChange?: (newValue: string) => void;
  showTicks?: boolean
  ticks?: number
}
interface SliderElement {
  addEventListener: Function
}
interface SliderChangeEvent {
  detail: { value: string }
}

const StepSlider = ({ id,step, minValue, maxValue, initialValue, showTicks, ticks, onSliderValueChange }: SliderProps) => {
  const sliderElement = useRef<SliderElement>(null);

  const onSliderChange = ({ detail }: SliderChangeEvent) => {
    if (onSliderValueChange) {
      onSliderValueChange(detail.value);
    }
  }
  useEffect(() => {
    if (sliderElement.current) {
      sliderElement.current.addEventListener("sliderChange", debounce(onSliderChange, 200));
    }
  }, [])

  return <>  
    <sdds-slider ref={sliderElement}
       id={id}
      step={step}
      min={minValue}
      max={maxValue}
      value={initialValue}
      input
      ticks={ticks}
      snap
      tooltip>
    </sdds-slider>
  </>;
}
export default memo(StepSlider)