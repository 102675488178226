import React, { ReactNode } from "react";
import { exportCscCodeResultsToExcel } from '../../helpers/serviceConfigurationService'
import { useTranslation } from "react-i18next";

type ExcelExportProps = {
  data: CscCodeResponseType[]
  children?: ReactNode
  disabled: boolean
  slot?:string
}

const CscCodeExcelExportButton = ({ slot, data, children, disabled }: ExcelExportProps) => {
  const { t } = useTranslation();
  
  return <button type="button" slot={slot} className='btn-export sdds-btn sdds-btn-primary sdds-btn-sm' onClick={() => exportCscCodeResultsToExcel(data,t)} 
          disabled={disabled}>{children && children}</button>
}

export default CscCodeExcelExportButton;
