import React, { useState, useEffect, useRef } from "react"

import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { useToast, Toast, Icons, DateSearch } from '@cd/sdds-common-components-react';

import './SearchMPLog.scss';
import moment from "moment";
import { setMPSearchInputsIntoStore } from "../../redux/store/MPLog";

interface SearchMPLogPropTypes {
  msgDirections: Array<any>
  logMsgTypes: Array<any>
  getMpLog: Function
  externalEquipmentReference: string
  resetpagination: boolean
  setResetPagination: Function
  resetData: boolean
}

const SearchMPLog = ({ msgDirections, logMsgTypes, getMpLog, externalEquipmentReference, resetpagination, setResetPagination, resetData }: SearchMPLogPropTypes) => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { hasError, errorMessage, mpLogSearchInput } = useAppSelector<MpLogStateType>(state => state.mpLogStore);
  const { toastQueue, addToast } = useToast()
  const [direction, setDirection] = useState<string>(mpLogSearchInput?.direction ? mpLogSearchInput?.direction : '');
  const [logMsgType, setLogMsgType] = useState<string>(mpLogSearchInput?.log ? mpLogSearchInput?.log : '');
  const [typeCodeList, setTypeCodeList] = useState<string>(mpLogSearchInput?.typeCodes ? mpLogSearchInput?.typeCodes : '');
  const [invalidDateMsg, setInvalidDateMsg] = useState<string>("");
  const directionRef = useRef<HTMLInputElement>(null);
  const typeRef = useRef<HTMLInputElement>(null);

  const onSearchClick = (startDate: string, endDate: string) => {
    if (!areValidDates(startDate, endDate)) return;
    let requestPayload = {
      externalEquipmentReference: externalEquipmentReference,
      queryId: "",
      direction: direction,
      log: logMsgType,
      typeCodes: typeCodeList,
      startTime: startDate,
      endTime: endDate
    }
    let mpSearchInputData: MPLogSearchInput = {
      startTime: requestPayload.startTime,
      endTime: requestPayload.endTime,
      direction: requestPayload.direction,
      log: requestPayload.log,
      typeCodes: requestPayload.typeCodes
    }
    dispatch(getMpLog(requestPayload))
    dispatch(setMPSearchInputsIntoStore(mpSearchInputData, requestPayload?.externalEquipmentReference))
    setResetPagination(!resetpagination)
  }

  /* Additional methods to address sdds-dropdown issue
      #Issue - when clicking outside the dropdown options container to close it,
      value becomes undefined but label remains the previously selected one
      #Issue - selected value is undefined when value of All is pre-set as ''. 
   */
  const mapDirectionValue = (value: string) => {
    if (!value) return direction; //return previous selection
    else if (value === 'All') return ''; //map All to '' after selection
    else return value;
  }
  const mapTypeValue = (value: string) => {
    if (!value) return logMsgType; //return previous selection
    else if (value === 'All') return ''; //map All to '' after selection
    else return value;
  }
  /* End of additional methods */

  const areValidDates = (startDate: string, endDate: string): boolean => {
    //MP logs can be retrieved only for a max period of 1 day at a time
    let duration = moment.duration(moment.utc(endDate).diff(moment.utc(startDate)));
    let timeSpanInHours = duration.asHours();
    let timeSpanInDays = duration.asDays();
    console.log("startDate ",startDate,"  endDate:",endDate)
    console.log("Days: ",timeSpanInDays,"  Hours:",timeSpanInHours)
    if (timeSpanInHours > 24) {
      console.log("in If")
      setInvalidDateMsg(t("TS_core:AllowedPeriodExceeded"));
    } else if (timeSpanInHours < 0 || timeSpanInDays <= 0) {
      console.log("in Else if")
      setInvalidDateMsg(t("TS_core:InvalidSearchPeriod"));
    } else {
      console.log("in Else")
      setInvalidDateMsg("");
    }

    return (timeSpanInHours <= 24 && timeSpanInHours > 0 && timeSpanInDays > 0)
  }
  useEffect(() => {
    if (hasError && errorMessage !== '') {
      addToast({
        title: t("TS_core:UnExpectedErrorTitle"), text: t("TS_core:UnExpectedErrorMessage"),
        type: "error"
      })
    }
  }, [hasError, errorMessage]);


  useEffect(() => {
    directionRef.current?.setAttribute('selected-option', ' ');
    setDirection('')
    typeRef.current?.setAttribute('selected-option', ' ');
    setLogMsgType('')
    setTypeCodeList("");
  }, [resetData])


  useEffect(() => {
    if (typeof mpLogSearchInput !== 'undefined') {
      setDirection(mpLogSearchInput.direction)

      if (direction !== '') {
        directionRef.current?.setAttribute('placeholder', direction);
      } else {
        directionRef.current?.setAttribute('placeholder', 'All');
      }
      directionRef.current?.setAttribute('selected-value', direction);
      directionRef.current?.setAttribute('selected-text', direction);
      setLogMsgType(mpLogSearchInput.log);

      if (logMsgType !== '') {
        typeRef.current?.setAttribute('placeholder', logMsgType);
      } else {
        typeRef.current?.setAttribute('placeholder', 'All');
      }
      typeRef.current?.setAttribute('selected-value', logMsgType);
      typeRef.current?.setAttribute('selected-text', logMsgType);
      setTypeCodeList(mpLogSearchInput.typeCodes)
    }
  }, [mpLogSearchInput])


  return (
    <div className="search-mp-log-section">
      <div className="sdds-row title-container">
        <div className="sdds-col-md-12">
          <div className="sdds-headline-05">{t("TS_core:SearchMPLog")} </div>
          <div className='sdds-row sdds-detail-03'><Icons.InfoIcon />{t("TS_core:AllTimeIsUTCTime")}</div>
        </div>
      </div>
      <div className="sdds-row sdds-u-mt2 sdds-body-02 input-row-1">
        <div className="dd-column">
          <strong>{t("TS_core:MessageDirection")} </strong>
          <div className="dropdown-containers">
            <sdds-dropdown
              size="medium"
              ref={directionRef}
              default-option={""}
              placeholder={t('TS_temp:All')} onClick={(value: any) => { setDirection(mapDirectionValue(value?.target?.value)) }} >
              {msgDirections.map((option: any) => (
                <sdds-dropdown-option value={option.value}>{option.key}</sdds-dropdown-option>))}
            </sdds-dropdown>
          </div>
        </div>
        <div className="dd-column">
          <strong>{t("TS_core:LogMessageType")} </strong>
          <div className="dropdown-containers">
            <sdds-dropdown
              size="medium"
              ref={typeRef}
              default-option=""
              placeholder={t('TS_temp:All')} onClick={(value: any) => { setLogMsgType(mapTypeValue(value?.target?.value)) }} >
              {logMsgTypes.map((option: any) => (
                <sdds-dropdown-option value={option.value}>{option.key}</sdds-dropdown-option>))}
            </sdds-dropdown>
          </div>
        </div>
        <div className="type-code-column">
          <strong>{t("TS_core:TypeCodes")} </strong>
          <div className="dropdown-containers">
            <input
              type="text"
              value={typeCodeList}
              className="sdds-textfield-input"
              placeholder={t("TS_core:TypeCodeHelpMsg")}
              title={t("TS_core:TypeCodeHelpMsg")}
              onChange={(e: any) => setTypeCodeList(e.target.value)}
            />
          </div>
        </div>

      </div>
      <DateSearch qsOptions={['Last30Mins', 'Last1Hour', 'Last4Hours', 'Last24Hours', 'PreviousDay', 'NextDay']}
        onSearchClick={onSearchClick}
        onValidate={areValidDates}
        validationMsg={invalidDateMsg}
      />
      <Toast toastQueue={toastQueue} />
    </div>
  )
}

export default SearchMPLog;