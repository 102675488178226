import React, { useContext, useEffect, useState } from 'react';
import { useAppSelector } from '../../../redux/store';
import SearchMPLog from '../../../components/SearchMpLog';
import MPLogDataTable from '../../../components/MPLogDataTable';
import { useAppDispatch } from '../../../redux/store';
import { callGetMPLogs, clearMpCommunicationData, sortMPLogData } from '../../../redux/store/MPLog';
import { each, filter, flatten, keys, reduce, reverse, sortBy, values } from 'lodash';
import './MPLog.scss';
import { UserContext } from '../../../appContext';



// eslint-disable-next-line no-empty-pattern
const MPLog = () => {

  const dispatch = useAppDispatch();
  const { results, searchEquipmentReference } = useAppSelector<MpLogStateType>(state => state.mpLogStore);
  const { selectedVehicle, selectedCustomer } = useAppSelector(state => state.universalSearch);
  const [sortedSearchResults, setSortedSearchResults] = useState<Array<MPLogRecordType> | []>([]);
  const [resetpagination, setResetPagination] = useState<boolean>(false);
  const [triggerReset, setTriggerReset] = useState<boolean>(false);
  const loggedInProfileInfo  = useContext<ProfileInformationType>(UserContext);

  const messageDirections = [
    { value: 'All', key: 'All' },
    { value: 'MT', key: 'MT' },
    { value: 'MO', key: 'MO' },
  ];
  const logMessageTypes = [
    { value: 'All', key: 'All' },
    { value: 'Application', key: 'Application' },
    { value: 'Protocol', key: 'Protocol' },
  ];

  const onSortData = (columnId: string, sortDirection: string) => {
    if (results && results.length > 0) {
      let sortedResults = sortBy(results, columnId);
      if (sortDirection === 'desc') {
        sortedResults = reverse(sortedResults);
      }
      dispatch(sortMPLogData(sortedResults as Array<MPLogRecordType>));
    }
  }

  const onSearch = (searchString: string) => {
    if (searchString.length > 0) {
      if (results && results.length > 0) {
        const splitedString = searchString.split(",");
        let filteredDataArray;
        if (splitedString && splitedString.length > 0) {
          filteredDataArray = splitedString.map((searchstr: string) => {
            searchstr = searchstr.trim();
            if (searchstr === "") return [];
            return results.filter(a => values(a).some((b) => {
              if (typeof b === 'string')
                return b.toLocaleLowerCase().includes(searchstr.toLocaleLowerCase())
              else
                return b;
            }
            ));
          })
        }
        setSortedSearchResults(flatten(filteredDataArray) as Array<MPLogRecordType>);
        return false;
      }
    } else {
      setSortedSearchResults(results);
    }
  }
  const onFilterByColumn = (filterColumns: IHashMap<String>) => {
    const columnNames = keys(filterColumns);
    if (columnNames.length > 0) {
      if (results && results.length > 0) {
        let filteredDataArray: Array<MPLogRecordType> =
          filter(results, (row: MPLogRecordType) => {
            let addResult = reduce<string,boolean>(columnNames, (addRow,columnName) => {
              let filterdColumn = false;
              
              let searchQuery = filterColumns[columnName];
              if (searchQuery && searchQuery !== '') {
                const splitedString = searchQuery.split(",");
                if (splitedString.length > 0) {
                  each(splitedString, (searchString) => {
                    if (searchString !== '') {
                      if (row[columnName].toLowerCase().indexOf(searchString.toLowerCase()) > -1) {
                        filterdColumn = true;
                      }
                    }
                  })
                }
              }
              if(addRow === null){
                return filterdColumn;
              }else{
                return addRow && filterdColumn
              }
            }, null as any);
            return addResult;
          });

        setSortedSearchResults(filteredDataArray as Array<MPLogRecordType>);
        return false;
      }
    } else {
      setSortedSearchResults(results);
    }
  }
  useEffect(() => {
    setSortedSearchResults(results)
  }, [results]);

  useEffect(() => {
    if (searchEquipmentReference !== selectedVehicle.externalEquipmentReference) {
      dispatch(clearMpCommunicationData())
      setTriggerReset(!triggerReset)
    }

  }, [selectedVehicle.externalEquipmentReference])

  return <>
    {((selectedCustomer && selectedCustomer?.scaniaLegalDocumentationSigned && selectedCustomer?.scaniaRead) || ((selectedCustomer === undefined || selectedCustomer === null) && (loggedInProfileInfo?.role === 'Admin' || loggedInProfileInfo?.role === 'GTS'))) &&
      <div className="panel active" >
        <div className='mp-log-page'>
          <div className='mp-log-container sdds-container-fluid'>
            <div className="ta-row sdds-row">
              <div className='sdds-no-padding sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12'>
                <SearchMPLog
                  msgDirections={messageDirections}
                  logMsgTypes={logMessageTypes}
                  getMpLog={callGetMPLogs}
                  externalEquipmentReference={selectedVehicle.externalEquipmentReference}
                  resetpagination={resetpagination}
                  setResetPagination={setResetPagination}
                  resetData={triggerReset}
                />
              </div>
            </div>
            <div className="dt-row sdds-row">
              <div className='dt-col sdds-no-padding sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 '>
                <div className='dt-container'>
                  <MPLogDataTable data={sortedSearchResults} vin={selectedVehicle.identification} onSearch={onSearch} onSortData={onSortData} onFilterByColumn={onFilterByColumn} paginationReset={resetpagination} resetData={triggerReset} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  </>
};

export default MPLog;
