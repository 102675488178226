import { TFunction } from "i18next";

const getColumnsConfig = (t: TFunction) => {
  return {
    column: {
      style: 'font-size:16px'
    },
    columns: [
      { columnid: 'timeRegistered', title: t("TS_core:TimeRegistered"), sortable: true },
      { columnid: 'gatewayReferenceId', title: t("TS_core:GatewayReferenceId"), sortable: true},
      { columnid: 'mobileId', title: t("TS_core:MobileId"), sortable: false },
      { columnid: 'model', title: t("TS_core:CommunicatorModel"), sortable: true,},
      { columnid: 'version', title: t("TS_core:Version"), sortable: false },
      { columnid: 'link', title: '', sortable: false }
    ]
  };
}

export {
  getColumnsConfig
}