import moment from 'moment';
import _ from 'lodash';


const utcFormatFromString = (datetime: string): string => {
  if (datetime === 'now') {
    return moment.utc().format('YYYY-MM-DD HH:mm:ss');
  }
  return datetime ? moment.utc(datetime).format('YYYY-MM-DD HH:mm:ss') : '-';
};

const utcFormatDayFromString = (datetime: string) : string => { 
  return datetime ? moment.utc(datetime).format('YYYY-MM-DD') : '-';
};

const utcFormatFromDate = (datetime: Date | string): string => moment.utc(datetime).format('YYYY-MM-DD HH:mm:ss');

const isoFormatFromDate = (datetime: Date | string): string => moment.utc(datetime).toISOString();

const utcFormatFromStringDateTime = (datetime: string): { utcDate: string, utcTime: string } => {
  let utcDate: string, utcTime: string;
  if (datetime === 'now') {
    utcDate = moment.utc().format('YYYY-MM-DD');
    utcTime = moment.utc().format('HH:mm:ss');
  }
  utcDate = datetime ? moment.utc(datetime).format('YYYY-MM-DD') : '';
  utcTime = datetime ? moment.utc(datetime).format('HH:mm:ss') : '';
  return { utcDate, utcTime };
};

const durationFormat = (formatedObj: customDateFormater): string => {
  const formatedValue = _.keys(formatedObj).map((key) => {
    if (formatedObj[key] !== -0) {
      return formatedObj[key] + key
    }
    return ""
  }).join(" ");
  return formatedValue;
}

const exportExcelUtcFormatFromString = (datetime: any): string => {
  if (datetime === 'now') {
    return moment.utc().format('YYYYMMDDHHmm');
  }
  return datetime ? moment.utc(datetime).format('YYYYMMDDHHmm') : '-';
};

export {
  utcFormatFromString,
  utcFormatFromDate,
  isoFormatFromDate,
  utcFormatFromStringDateTime,
  durationFormat,
  exportExcelUtcFormatFromString,
  utcFormatDayFromString
};
