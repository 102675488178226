import { AxiosPromise } from 'axios';
import { csttFacadeApi } from '@cd/sdds-common-components-react';

export const fetchEquipmentInformation = (externalEquipmentReference: string): AxiosPromise<TelemeticsEquipmentInformationType> => {
  return csttFacadeApi.get(`/csttfacade/telematicsunit/v1/getequipmentinformation/${externalEquipmentReference}`);
}

const TelematicsUnitAdapter = {
  fetchEquipmentInformation
}

export default TelematicsUnitAdapter;