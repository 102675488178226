import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import BatchSearchDataTable from '../../components/BatchSearchDataTable';
import { clearBatchSearchData, searchForEquipmentsInBatch } from "../../redux/store/batchSearch";
import './BatchSearch.scss';
import { Spinner, useToast, Toast } from '@cd/sdds-common-components-react';
import _ from 'lodash';
import {LocalizationHelper} from '@cd/sdds-common-components-react';

type BatchSearchProps = {

}

const BatchSearch = (props: BatchSearchProps) => {
  const { toastQueue, addToast } = useToast()
  const dispatch = useAppDispatch();
  const { t } = LocalizationHelper.useLocalize();
  const { searchResults, isLoading, hasError, errorMessage, notFound, percentage } = useAppSelector<BatchSearchStateType>(state => state.batchSearchStore);
  const textAreaRef = React.createRef<HTMLTextAreaElement>();
  const [sortedSearchResults, setSortedSearchResults] = useState<Array<SimplifiedBatchSearchResultType>>([]);

  useEffect(() => {
    if (searchResults && searchResults.length >= 0) {
      setSortedSearchResults(searchResults);
    }
  }, [searchResults]);

  useEffect(() => {
    if (hasError && errorMessage !== '') {
      addToast({
        title: t("TS_core:UnExpectedErrorTitle"), text: t("TS_core:UnExpectedErrorMessage"),
        type: "error"
      })
    }
  }, [hasError, errorMessage]);

  const onSearchClick = () => {
    dispatch(clearBatchSearchData());
    let searchInput = textAreaRef.current?.value;
    if (searchInput) {
      // First regex remove all spaces, second remove any multiples of line breaks above 1, the third remove any trailing breaks
      searchInput = searchInput?.replace(/[ ]{1,}/g, '').replace(/(\r\n|\r|\n){2,}/g, '$1').replace(/(\r\n|\r|\n)+$/, '');
      let inputs = searchInput?.split('\n');
      inputs = _.uniq<string>(inputs)
      if (textAreaRef.current) {
        textAreaRef.current.value = inputs.join("\n");
      }
      if(inputs.length <= 10000){
        dispatch(searchForEquipmentsInBatch(inputs));
      }else{
        inputs = _.take(inputs, 10000);
        dispatch(searchForEquipmentsInBatch(inputs));
        addToast({
          title: t("TS_core:SearchInputWarning"), text: t("TS_temp:SearchInputWarningDescription", ['10000','10000','10000']),
          type: "warning"
        })
      }
    }
  }
  const onClearClick = () => {
    dispatch(clearBatchSearchData());
    if (textAreaRef.current) {
      textAreaRef.current.value = "";
    }
  }
  const onSortData = (columnId: string, sortDirection: string) => {
    if (searchResults && searchResults.length > 0) {
      let sortedResults = _.sortBy(searchResults, columnId);
      if (sortDirection === 'desc') {
        sortedResults = _.reverse(sortedResults);
      }
      setSortedSearchResults(sortedResults as Array<SimplifiedBatchSearchResultType>);
    }
  }
  const onSearch = (searchString: string) => {
    if (searchString.length > 0) {
      if (searchResults && searchResults.length > 0) {
        const splitedString = searchString.split(",");
        let filteredDataArray;
        if (splitedString && splitedString.length > 0) {
          filteredDataArray = splitedString.map((searchstr: string) => {
            searchstr = searchstr.trim();
            if (searchstr === "") return [];
            return searchResults.filter(a => _.values(a).some((b: any) => {
              if (typeof b === 'string') {
                return b?.toLocaleLowerCase().includes(searchstr.toLocaleLowerCase())
              } else if (typeof b === 'object') {
                return JSON.stringify(b).toLocaleLowerCase().includes(searchstr.toLocaleLowerCase())
              } else {
                return b?.toString().toLocaleLowerCase() === searchstr.toLocaleLowerCase()
              }
            }
            ));
          })
        }
        setSortedSearchResults(_.uniq(_.flatten(filteredDataArray) as Array<SimplifiedBatchSearchResultType>));
        return false;
      }
    } else {
      setSortedSearchResults(searchResults);
    }
  }

  return <>
    <div className='batch-search-page'>
      <div className='batch-search-container sdds-container-fluid'>
        <div className="ta-row sdds-row">
          <div className='ta-col sdds-col-xs-12 sdds-col-sm-5 sdds-col-md-5 sdds-col-lg-5 sdds-col-xlg-5'>
            <div className='sdds-headline-07 sdds-u-mb2 sdds-body-02'>{t("TS_core:BatchSearchInputLabel")} <br/> <span className='sdds-detail-03 batchSearchNote'>{t("TS_temp:BatchSearchLabelNote", ['10000','10000','10000'])}</span></div>
            <textarea className='sdds-textarea-input sdds-body-02' ref={textAreaRef} title="Search Area" placeholder={t("TS_core:SearchAreaPlaceholder")}></textarea>
          </div>

          <div className='btn-col sdds-no-padding sdds-col-xs-12 sdds-col-sm-2 sdds-col-md-1 sdds-col-lg-1 sdds-col-xlg-1'>
            <div className='flex'>
              <div className='btn-wrapper'>
                <button type='button' className='sdds-btn sdds-btn-primary sdds-btn-fullbleed' onClick={onSearchClick}>{t("TS_core:Search")}</button>
                <button type='button' className='sdds-btn sdds-btn-secondary sdds-btn-fullbleed' onClick={onClearClick}>{t("TS_core:Clear")}</button>
              </div>
            </div>
          </div>
          <div className='sdds-col-md-1-offset sdds-col-xs-12 sdds-col-sm-5 sdds-col-md-5 sdds-col-lg-5 sdds-col-xlg-5'>
            <div className='flex'>
              {/* Next to elements are using flex direction column-reverse. plz don't change their placements */}
              {notFound && notFound.length > 0 && <div className='not-found-wrapper'>
                <div className='not-found-box'>
                  {notFound && notFound.map((stringQuery: string) =>
                    <div className='sdds-body-02'>{stringQuery}</div>
                  )}
                </div>
                <div className='sdds-headline-07 sdds-u-mb2'>{t("TS_core:EquipmentNotFound") + `(${notFound.length})`}</div>
              </div>}
            </div>
          </div>
        </div>
        <div className="dt-row sdds-row">
          <div className='dt-col sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 '>
            <div className='dt-container '>
              {<BatchSearchDataTable data={sortedSearchResults} onSortData={onSortData} onSearch={onSearch} />}
              {isLoading && <div className='loader'>
                  <div className="spinner">
                    {t("TS_core:LoadingVehicleData")}
                    <Spinner size='md' value={percentage} />
                  </div>
                </div>}
            </div>
          </div>
        </div>
        <Toast toastQueue={toastQueue} />
      </div>
    </div>
  </>
};

export default BatchSearch;
