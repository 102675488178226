import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { durationFormat } from './timeFormatter';

dayjs.extend(duration);

const calculationTimeDelay = (preTime : string, postTime: string) : {timeDelay : string, timeDelaySecond : number} => {

  let timeDelay : string,  timeDelaySecond : number, timeDifference: any;
  if (preTime !== null && postTime !== null) {
    const convetedPreTime = dayjs.utc(preTime);
    const convetedPostTime = dayjs.utc(postTime);

    if(convetedPostTime > convetedPreTime){
      timeDifference =dayjs.duration(convetedPostTime.diff(convetedPreTime));
    }else{
      timeDifference =dayjs.duration(convetedPreTime.diff(convetedPostTime));
    }

    //due to dayJs duration formating method issue (timeDifference.format('D[d] H[h] m[m] s[s]') , implemented custom format method
    const convertedTimeDiff: customDateFormater = {
        years : timeDifference.years(),
        months : timeDifference.months(),
        days : timeDifference.days(),
        hours : timeDifference.hours(),
        minutes : timeDifference.minutes(),
        seconds : timeDifference.seconds(),
        milliseconds : timeDifference.milliseconds()
      }
     timeDelay = durationFormat(convertedTimeDiff);
     timeDelaySecond = timeDifference.asSeconds();
  }
  else{
    timeDelay='';
    timeDelaySecond=0;
  }
  return {timeDelay, timeDelaySecond};
};


export {
  calculationTimeDelay
};
