import { createSlice } from '@reduxjs/toolkit'
import { sendGetCsdsByAttributes, sendGetCsdDefinitionById } from '../../adapters/CsdAdapter/csdAdapter';
import _ from 'lodash';
import { utcFormatFromStringDateTime } from '../../helpers/timeFormatter';

const getInitialState = (): CsdStateType => {
  const mpLogInitialState: CsdStateType = {
    isLoading: false,
    hasError: false,
    errorMessage: "",
    csdsList: [],
    notFound: false,
    csdDefinition: null,
    isCsdDefLoading: false,
    currentVehicleExtRef: ""
  };
  return mpLogInitialState
}

// Slice
const slice = createSlice({
  name: 'csdState',
  initialState: getInitialState(),
  reducers: {
    updateIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    updateError: (state, action) => {
      state.hasError = action.payload !== '';
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    updateCsdList: (state, action) => {
      const { data, externalEquipmentReference } = action.payload;
      state.csdsList = data;
      state.currentVehicleExtRef = externalEquipmentReference;
    },
    updateCsdDefinition: (state, action) => {
      state.csdDefinition = action.payload;
    },
    updateNotFound: (state, action) => {
      state.notFound = action.payload;
    },
    updateIsCsdDefLoading: (state, action) => {
      state.isCsdDefLoading = action.payload
    },
  },
});

export default slice.reducer

// Actions
const { updateIsLoading, updateError, updateCsdList, updateCsdDefinition, updateIsCsdDefLoading } = slice.actions

export const fetchCSDsByAttributes = () => async (dispatch: any, getState: any) => {
  try {
    const { universalSearch } = getState();
   
    dispatch(updateIsLoading(true));
    dispatch(updateCsdList({ data: [], externalEquipmentReference: "" }));

    const attributesList: Array<CsdAttribute> = [];
    var wantedKeys = ['vin', 'registrationnumber', 'chassisnumber', 'externalequipmentreference', 'alias'];

    _.forEach(universalSearch.selectedVehicle, function (value, key) {
      if (_.includes(wantedKeys, key.toLowerCase()) && value) {
        var keyCapitalized = key.charAt(0).toUpperCase() + key.slice(1); //Map against dynamodb attribute name (case sensitive)
        attributesList.push({ 'value': value, 'type': keyCapitalized });
      }
    });

    if (universalSearch.selectedVehicle.equipmentType === 'Engine' && (universalSearch.selectedVehicle.chassisNumber || universalSearch.selectedVehicle.vin)) {
      attributesList.push({ 'value': universalSearch.selectedVehicle.chassisNumber ? universalSearch.selectedVehicle.chassisNumber : universalSearch.selectedVehicle.vin, 'type': 'EngineNumber' });
    }

    const res = await sendGetCsdsByAttributes(attributesList)
    if (res.data && res.data && res.data) {
      const csdList = _.chain(res.data)
        .sortBy("creationTime")
        .reverse()
        .map((row: CsdType) => {
          const utcTimestamp = utcFormatFromStringDateTime(row.creationTime);
          row.utcCreationTime = utcTimestamp.utcDate + " " + utcTimestamp.utcTime;
          return row;
        })
        .value()
      dispatch(updateCsdList({ data: csdList, externalEquipmentReference: universalSearch.selectedVehicle.externalEquipmentReference }))
      dispatch(updateIsLoading(false));
    }
  } catch (e: any) {
    dispatch(updateIsLoading(false));
    dispatch(updateError(e.message));
    return console.error(e);
  }
}


export const fetchCSDDefinitionByid = (definitionId: string, systemName: string) => async (dispatch: any) => {
  try {
    dispatch(updateIsCsdDefLoading(true));
    const res = await sendGetCsdDefinitionById(definitionId, systemName.replace(" ", '+'));
    if (res.data && res.data && res.data) {
      dispatch(updateCsdDefinition(res.data))
      dispatch(updateIsCsdDefLoading(false));
    }
  } catch (e: any) {
    dispatch(updateIsCsdDefLoading(false));
    dispatch(updateError(e.message));
    return console.error(e);
  }
}
