import React, { useState } from 'react';

import VehicleWarpper from '../../components/VehicleRtcSearch/Vehicle';
import { Icons, Spinner } from '@cd/sdds-common-components-react';
import { useTranslation } from 'react-i18next';

import './VehicleRtc.scss';
import SearchVehicleRtc from '../../components/VehicleRtcSearch';
import { useAppSelector } from '../../redux/store';
import RtcWarpper from './rtcWarpper';
import { cleareRTCDetails, cleareVehicleDetails, fetchRtcDetails, fetchRtcHistory, fetchVehicleDetails, fetchVehicleHistory, searchByMobileId, searchByVin } from '../../redux/store/vehicleRtcHistory';
import { useDispatch } from 'react-redux';



const VehicleRtcHistory = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { isLoading, rtcDetails, rtcHistoryList, vehicleDetails, vehicleHistoryList, isRtcHistoryLoading, isVehicleHistoryLoading } = useAppSelector<VehicleRtcHistoryType>(state => state.vehicleRtcHistory);
	const [selectedEquipmentType, setSelectedEquipmentType] = useState<string>('');
	const [selectedInput, setSelectedInput] = useState<string>('');

	const handleSearch = (equipmentType: string, searchInputValue: string) => {
		searchInputValue = searchInputValue.trimEnd().trimStart();
		setSelectedEquipmentType(equipmentType);
		setSelectedInput(searchInputValue);
		let guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

		if (equipmentType === 'vehicle') {
			dispatch(cleareVehicleDetails());
			// Check if it is guid
			if (guidRegex.test(searchInputValue) === true) {
				dispatch(fetchVehicleDetails(searchInputValue));
				dispatch(fetchVehicleHistory(searchInputValue));
			}
			// Search by VIN
			else
				dispatch(searchByVin(searchInputValue));
		}
		else if (equipmentType === 'rtc') {
			dispatch(cleareRTCDetails());
			if (guidRegex.test(searchInputValue) === true) {
				dispatch(fetchRtcDetails(searchInputValue));
				dispatch(fetchRtcHistory(searchInputValue));
			}
			else {
				dispatch(searchByMobileId(searchInputValue));
			}
		}
	}
	return <>
		<div className='vehicle-rtc-page'>
			<div className='vehicle-rtc-container'>
				<SearchVehicleRtc onSearch={handleSearch} equipmentType={selectedEquipmentType} searchInput={selectedInput} />
				<div className='details-row'>					

					{((selectedEquipmentType === 'vehicle' && vehicleDetails === undefined && vehicleHistoryList.length === 0) ||
						(selectedEquipmentType === 'rtc' && rtcDetails === undefined && rtcHistoryList.length === 0)) ?
						<>{!isLoading && !isRtcHistoryLoading && !isVehicleHistoryLoading && <><div className="showInfo"><Icons.InfoIcon /><span className="noData">{t("TS_core:NoDataFound")} </span></div> </>}</>
						: <>
							{(selectedEquipmentType === 'vehicle' || selectedEquipmentType === 'rtc') && <div className='utc-info tds-body-02'><Icons.InfoIcon />{t("TS_core:AllTimeIsUTCTime")}</div>}
							{selectedEquipmentType === 'vehicle' && !isLoading && <VehicleWarpper vehicleDetails={vehicleDetails} vehicleHistoryDataList={vehicleHistoryList} isLoading={isLoading} isVehicleHistoryLoading={isVehicleHistoryLoading} onHandleRedirect={(searchInput: string) => handleSearch('rtc', searchInput)} />}
							{selectedEquipmentType === 'rtc' && !isLoading && <RtcWarpper rtcDetails={rtcDetails} rtcHistoryDataList={rtcHistoryList} isRtcHistoryLoading={isRtcHistoryLoading} onHandleRedirect={(searchInput: string) => handleSearch('vehicle', searchInput)} />}
						</>}
					{isLoading && <div className='loader'><Spinner /></div>}
				</div>
			</div>
		</div>
	</>
}

export default VehicleRtcHistory;