import { createSlice } from '@reduxjs/toolkit'
import { } from '../../adapters/ServiceConfigurationAdapter';
import { csSchemaVariables, fiSchemaVariables, posSchemaVariables } from '../../helpers/historicalData';
import _ from "lodash"

const getInitialState = (): HistoricalDataStateType => {
  const State: HistoricalDataStateType = {
    isLoading: false,
    hasError: false,
    errorMessage: "",
    historicalDataVariables: [],
    selectedColumns:[],
  };
  return State;
}

// Slice
const slice = createSlice({
  name: 'historicalDataVariables',
  initialState: getInitialState(),
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    updatehistoricalDataVariables: (state, action) => {
      state.historicalDataVariables = action.payload;
    },
    updateSelectedColumns: (state, action) => {
      state.selectedColumns = action.payload;
    },
    updateError: (state, action) => {
      state.hasError = action.payload !== '';
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
  },
});
export default slice.reducer

// Actions
const { setIsLoading, updatehistoricalDataVariables, updateError, updateSelectedColumns} = slice.actions

export const loadhistoricalDataVariables = (messageType: string) => async (dispatch: any, getState: any) => {
  try{
    dispatch(setIsLoading(true));
    let {historicalDataStore} = getState();

    if(messageType.toLowerCase() === 'currentstatus'){
      let updatedCurrentStatus = historicalDataStore.historicalDataVariables.concat(csSchemaVariables);
      dispatch(updatehistoricalDataVariables(GetUniqueHistoricalDataList(updatedCurrentStatus)));
    }else if(messageType.toLowerCase() === 'position'){
      let updatedPosition = historicalDataStore.historicalDataVariables.concat(posSchemaVariables);
      dispatch(updatehistoricalDataVariables(GetUniqueHistoricalDataList(updatedPosition)));
    }else{
      let updatedHistoricalData = historicalDataStore.historicalDataVariables.concat(fiSchemaVariables);
      dispatch(updatehistoricalDataVariables(GetUniqueHistoricalDataList(updatedHistoricalData)));
    }
    dispatch(setIsLoading(false));
  } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}

const GetUniqueHistoricalDataList = (array : any): any =>{
  return _.uniqWith(array, (item : any, currentItem) => item.value === currentItem.value).sort((a : any, b: any) => a.value > b.value ? 1 : -1);
}

export const clearHistoricalDataVariables = () => async (dispatch: any) => {
  try {
    dispatch(updatehistoricalDataVariables([]));
  } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}

export const updateColumnSelection = (columns:CommunicationColumnConfigType[]) => async (dispatch: any) => {
  try {
    dispatch(updateSelectedColumns(columns));
  } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}