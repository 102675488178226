import { createSlice } from '@reduxjs/toolkit'
import { getMPLogs } from '../../adapters/MPLogAdapter';
import { sortBy,reverse } from 'lodash';

const getInitialState = (): MpLogStateType => {
  const mpLogInitialState: MpLogStateType = {
    isLoading: false,
    hasError: false,
    errorMessage: "",
    results: [],
    searchEquipmentReference: "",
    mpLogSearchInput: undefined
  };
  return mpLogInitialState
}
// Slice
const slice = createSlice({
  name: 'mpLogState',
  initialState: getInitialState(),
  reducers: {
    updateIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    updateError: (state, action) => {
      state.hasError = action.payload !== '';
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    updateResults: (state, action) => {
      state.results = action.payload;
    },
    setSearchEquipmentReference: (state, action) => {
      state.searchEquipmentReference = action.payload
    },
    updateSearchInputData: (state, action) => {
      state.mpLogSearchInput = action.payload;
    }
  },
});
export default slice.reducer

// Actions
const { updateIsLoading, updateError, updateResults, setSearchEquipmentReference, updateSearchInputData } = slice.actions

export const callGetMPLogs = (requestPayload: MPLogRequestPayloadType) => async (dispatch: any) => { 
  try {
    dispatch(updateIsLoading(true));
    dispatch(updateResults([]))
    const res = await getMPLogs(requestPayload)
    if (res.data && res.data.state && res.data.state === "Completed") {
      if (res.data?.results && res.data?.results) {
        const results = reverse(sortBy(res.data.results,"time"))
        dispatch(updateResults(results))
        dispatch(updateIsLoading(false));
      }
    }
    else if (res.data && res.data.state && res.data.state === "Running") {
      let newRequestPayload = { ...requestPayload }
      newRequestPayload.queryId = res.data.queryId;
      dispatch(callGetMPLogs(newRequestPayload))
    }

  } catch (e: any) {
    dispatch(updateIsLoading(false));
    dispatch(updateError(e.message));
    return console.error(e);
  }
}
export const sortMPLogData = (sortedMpLogData: MPLogRecordType[]) => async (dispatch: any) => {
  try {
    dispatch(updateResults(sortedMpLogData));
  } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}

export const setMPSearchInputsIntoStore = (mpLogSearchInput: MPLogSearchInput, externalEquipmentReference: string) => async (dispatch: any) => {
  try{
    dispatch(setSearchEquipmentReference(externalEquipmentReference));
    dispatch(updateSearchInputData(mpLogSearchInput));
  } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}

export const clearMpCommunicationData = () => async (dispatch: any) => {
  try { 
  dispatch(updateResults([]));
  dispatch(updateSearchInputData(undefined));
  dispatch(setSearchEquipmentReference(""));
  dispatch(updateError(""));

} catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}

