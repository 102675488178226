/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../redux/store';
import { EquipmentOverview, LoadAllSubscriptions, LoadLatestVehicleData, loadCommunicatorData, UniversalSearchActions, loadLatestDataFromCdc } from '@cd/sdds-common-components-react';
import { utcFormatDayFromString, utcFormatFromString } from '../../helpers/timeFormatter';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchCSDsByAttributes } from '../../redux/store/CSDsStore';
import CSDMainWrapper from '../../components/ConnectedServicesDeviations/CSDMainWrapper';
import './equipment-csd.scss'

type ConnectedServiceDeviationProps = {
  loggedInProfileInfo: ProfileInformationType
}

const ConnectedServiceDeviation = ({ loggedInProfileInfo }: ConnectedServiceDeviationProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()

  const { communicatorInfo, latestInfo, subscriptionInfo }: any = useAppSelector<RootState>(state => state);
  const { selectedVehicle, isEquipmentApisLoading, selectedCustomer, saStatus, saStatusDate, saStatusLoading, csdCount, csdDate, csdStatsLoading, allCustomersForVehicle, searchVehicleReference } = useAppSelector<SearchState>(state => state.universalSearch);
  const { csdsList, isLoading, csdDefinition, isCsdDefLoading } = useAppSelector<CsdStateType>(state => state.csdState)
  const [eqSearchStatus, setEqSearchStatus] = useState<boolean>(false);

  let { idParam } = useParams<any>();
  useEffect(() => {
    if (idParam && idParam !== searchVehicleReference) {
      dispatch(UniversalSearchActions.fetchEquipment(idParam))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idParam])

  useEffect(() => {   
    if (selectedVehicle && selectedVehicle.externalEquipmentReference !== '00000000-0000-0000-0000-000000000000') {
      if (subscriptionInfo.equipmentVehicleReference !== selectedVehicle.externalEquipmentReference) {          
        dispatch(LoadAllSubscriptions(selectedVehicle.externalEquipmentReference));
        dispatch(loadCommunicatorData(selectedVehicle.externalEquipmentReference));
      }        
    //load data for displaying configuration status & csd stats in equipment overview      
    dispatch(UniversalSearchActions.loadAggregateData(selectedVehicle.externalEquipmentReference));
    dispatch(UniversalSearchActions.loadCsdStats(selectedVehicle.externalEquipmentReference));
    }
}, [selectedVehicle])

  useEffect(() => {
    if(selectedVehicle){
      if (!idParam) {
        navigate('/connected-services-deviations/' + selectedVehicle.externalEquipmentReference, { replace: true })
      }   
      onGridReloadAction() //check for new deviations on vehicle change   
    }    
  }, [selectedVehicle])

  useEffect(() => {
    if(selectedVehicle)
    onGridReloadAction() //check for new deviations on every load
  }, []);

  useEffect(() => { 
   
    if(!subscriptionInfo.equipmentVehicleReference) return;
    
     if(subscriptionInfo.equipementTotalSubscribedCustomers?.length === 0){      
      dispatch(UniversalSearchActions.saveAllCustomersForVehicle([]));
      return;
    }  
   
    //iterate through the active subscription packages to get other customers for the vehicle besides the owner
    let customersWithSubscriptions = [...allCustomersForVehicle]
     subscriptionInfo.equipementTotalSubscribedCustomers.forEach( (p:any) => {      
      var aCustomer: SimpleCustomerType = {
        externalCustomerReference: p.externalCustomerReference,
        name: p.customerName,
      }
      if (p.hasActiveSubscriptions && !allCustomersForVehicle.find((element) => element.externalCustomerReference === aCustomer.externalCustomerReference) ) {        
        customersWithSubscriptions.push(aCustomer);
      }
    }); 
    //update customerList in store 
    dispatch(UniversalSearchActions.saveAllCustomersForVehicle(customersWithSubscriptions));    
  }, [subscriptionInfo.equipmentVehicleReference, subscriptionInfo.equipementTotalSubscribedCustomers.length]);

  useEffect(() => {    
    if(!selectedCustomer) return;
    
    /*  - selectedCustomer by default is always the vehicle owner
        - check and add vehicle owner to allCustomers list (sometimes the vehicle owner doesnt have an active subscription on the vehicle) */
    if(!allCustomersForVehicle.find((element) => element.externalCustomerReference === selectedCustomer.externalCustomerReference)){
      let allSavedCustomers = [...allCustomersForVehicle]
      allSavedCustomers.unshift(selectedCustomer); // always add vehicle owner as the first option in allCustomers list      
      dispatch(UniversalSearchActions.saveAllCustomersForVehicle(allSavedCustomers))//update in store customerList
    }      
  }, [selectedCustomer]); 


  const onGridReloadAction = () =>{
    dispatch(fetchCSDsByAttributes())
  }
  const reloadLatestData = (externalEquipmentReference:string)=>{
    if(communicatorInfo.EquipmentInformation.rtcType.toLowerCase() === 'c400'){
      dispatch(loadLatestDataFromCdc(externalEquipmentReference))
    }else{
      dispatch(LoadLatestVehicleData(externalEquipmentReference))
    }
  }

  return <>
   {((selectedCustomer && selectedCustomer?.scaniaLegalDocumentationSigned && selectedCustomer?.scaniaRead) || ((selectedCustomer === undefined || selectedCustomer === null) && (loggedInProfileInfo?.role === 'Admin' || loggedInProfileInfo?.role === 'GTS'))) &&
    <div className='equipment-csd-page'>
      
      <EquipmentOverview searchStatus={eqSearchStatus} setSearchStatus={setEqSearchStatus}
        vehicle={selectedVehicle}
        customer={selectedCustomer}
        allCustomers={allCustomersForVehicle}
        subscriptionInfo={subscriptionInfo}
        reLoadAllSubscriptions={(externalEquipmentReference: string) => { dispatch(LoadAllSubscriptions(externalEquipmentReference)); }}
        communicatorInfo={communicatorInfo}
        reloadCommunicatorInfo={(externalEquipmentReference: string) => { dispatch(loadCommunicatorData(externalEquipmentReference)); }}
        latestInfo={latestInfo}
        isScaniaRead={selectedCustomer?.scaniaRead && selectedCustomer?.scaniaLegalDocumentationSigned}
        reLoadLatestVehicleData={reloadLatestData}
        isEquipmentApisLoading={isEquipmentApisLoading}
        equipmentRedirectPath="communication"
        loggedInProfileInfo={loggedInProfileInfo}
        saStatus={saStatus}
        isSaStatusLoading={saStatusLoading}
        saStatusDate={utcFormatFromString(saStatusDate)}
        csdCount={csdCount}
        csdDate={utcFormatDayFromString(csdDate)}
        isCsdStatsLoading={csdStatsLoading}
      />
      <div className="right-side-view">
        {selectedVehicle &&
          <CSDMainWrapper
            csdList={csdsList}
            selectedVehicle={selectedVehicle}
            isLoading={isLoading}
            isCsdDefLoading={isCsdDefLoading}
            csdDefinition={csdDefinition} 
            onGridReloadAction={onGridReloadAction}
          />
        }
      </div>
    </div>
    }
  </>
};

export default ConnectedServiceDeviation;
