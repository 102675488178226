import React, { useMemo, useState, useEffect } from "react";
import { getColumnsConfig } from "../../helpers/MPLogSearchService";
import MPLogExportButton from "../MPLogExportButton";
import ExportIcon from "../../icons/ExportIcon";
import DateTimeSeperator from "../CommunicationHistory/DateTimeSeperator";
import { DataTable, DataRow, DataCell, Pagination } from '@cd/sdds-common-components-react'
import { useAppSelector } from '../../redux/store';
import { Spinner } from '@cd/sdds-common-components-react';
import { useLocalize } from "../../hooks/useLocalize";

interface MPLogDataTablePropTypes {
  data: Array<any>
  vin: string
  onSortData?: (columnId: string, sortDirection: string) => void
  onSearch?: (searchString: string) => void
  paginationReset: boolean
  resetData: boolean
  onFilterByColumn: (columns: IHashMap<String>) => void
}

const MPLogDataTable = ({ data, vin, onSortData, onSearch, paginationReset, resetData, onFilterByColumn }: MPLogDataTablePropTypes) => {
  const { t } = useLocalize();
  const columnConfig = getColumnsConfig(t);
  const { isLoading } = useAppSelector<MpLogStateType>(state => state.mpLogStore);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState(data);
  let pageSize = 20;

  const handlePaginationData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    if (firstPageIndex > tableData.length) {
      setCurrentPage(1)
    }
    return tableData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, 3, pageSize, tableData]);

  useEffect(() => {
    setCurrentPage(1)
  }, [paginationReset])

  useEffect(() => {
    setTableData([]);
  }, [resetData])

  useEffect(() => {
    setTableData(data);
  }, [data])

  return <>
    <DataTable
      title={t('TS_core:ResultsFound', [tableData.length.toString()])}
      columns={columnConfig.columns}
      onSearchChange={onSearch}
      onSort={onSortData}
      onFilterColumn={onFilterByColumn}
      actionBar={<MPLogExportButton vin={vin} slot="sdds-table__actionbar" data={tableData} disabled={!tableData || tableData.length === 0}>
        <span>{t("TS_core:ExportToExcel")}</span><span className='sdds-btn-icon'><ExportIcon /></span>
      </MPLogExportButton>} >
      <>
        {isLoading && <DataRow key="row-0"><DataCell colSpan={columnConfig.columns.length}><Spinner /></DataCell></DataRow>}
        {!isLoading && handlePaginationData.map((row, index) => {
          return <DataRow key={`row-${index}`}>
            {columnConfig.columns.map((col: ColumnConfigType, colIndex: number) => {
              switch (col.columnid) {
                case 'time':
                  return <DataCell key={`col-index-${colIndex}`}>
                    {row.time && <><DateTimeSeperator inputDateTime={row.time} /></>}
                  </DataCell>
                default:
                  return <DataCell key={`col-index-${colIndex}`}>{row[col.columnid]}</DataCell>
              };
            })}
          </DataRow>;
        })
        }
      </>
    </DataTable>
    <div className="sdds-col-md-12 sdds-headline-06 vertical-center">
      {!isLoading && handlePaginationData.length > 0 && <Pagination currentPage={currentPage} totalCount={tableData.length} pageSize={pageSize} onPageChange={(page: any) => setCurrentPage(page)} />}
    </div>
  </>
}
export default MPLogDataTable;