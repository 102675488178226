import { AxiosPromise } from 'axios';
import { csttFacadeApi } from '@cd/sdds-common-components-react';


export const fetchSupportMessages = (externalEquipmentReference: string): AxiosPromise<Array<UnitMessageType>> => {
  return csttFacadeApi.get(`/csttfacade/mpmessenger/v1/unitmessages?externalEquipmentReference=${externalEquipmentReference}`);
}

export const requestRtcLog = (externalEquipmentReference: string): AxiosPromise => {
  return csttFacadeApi.post(`/csttfacade/mpmessenger/v1/requestrtclog?externalEquipmentReference=${externalEquipmentReference}`);
}

export const switchSSHPort = (externalEquipmentReference: string, status: boolean): AxiosPromise => {
  return csttFacadeApi.post(`/csttfacade/mpmessenger/v1/switchrtcsshport?externalEquipmentReference=${externalEquipmentReference}&status=${status}`);
}

export const getMessageContents = (messageId: string): AxiosPromise<String> => {
  return csttFacadeApi.get(`/csttfacade/mpmessenger/v1/unitmessage/${messageId}/content`);
}

export const createNotification = (externalEquipmentReference: string, vin: string): AxiosPromise => {
  return csttFacadeApi.post(`/csttfacade/mpmessenger/v1/supportmessage/notification?externalEquipmentReference=${externalEquipmentReference}&vin=${vin}`);
}

export const fetchNotification = (externalEquipmentReference: string): AxiosPromise<Array<MpNotificationMessageType>> => {
  return csttFacadeApi.get(`/csttfacade/mpmessenger/v1/supportmessage/notification?externalEquipmentReference=${externalEquipmentReference}`);
}
export const downloadLogFile = (messageId: string): AxiosPromise => {
  return csttFacadeApi({ url: `/csttfacade/mpmessenger/v1/unitmessage/${messageId}/download`, method: "GET" });
}

const MPAdapter = {
  fetchSupportMessages,
  requestRtcLog,
  switchSSHPort,
  getMessageContents,
  createNotification,
  fetchNotification
};

export default MPAdapter;