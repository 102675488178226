import React from 'react'
import "./csd-details.scss";
import { Icons, Spinner, TabsComponent } from '@cd/sdds-common-components-react';
import { useTranslation } from 'react-i18next';
import DateTimeSeperator from '../../CommunicationHistory/DateTimeSeperator';
import CsdDefinition from '../CsdDefinition/CsdDefinition';


interface CsdDetailsPropType {
  csd: CsdType
  onClose: () => void
  csdDefinition: CsdDefinitionType | null
  selectedVehicle: VehicleType
  isCsdDefLoading: boolean
}

const CsdDetails = ({ csd, csdDefinition, onClose, selectedVehicle, isCsdDefLoading }: CsdDetailsPropType) => {
  const { t } = useTranslation()
  return <div className='csd-details-wrapper'>
    <div className='csd-details-header'>
      <span className='sdds-headline-04'>{t("TS_core:CsdDetails")}</span>
      <button onClick={onClose}><Icons.CrossIcon /></button>
    </div>
    <TabsComponent titles={[`${t("TS_core:Instance")}`, `${t("TS_core:Definition")}`]}  >
      <div className='instance-panel'>
        <div className='white-box'>
          <div>
            <span className='sdds-headline-06'>{t("TS_core:SystemName")}</span><span>{csd.systemName}</span>
          </div>
          <div>
            <span className='sdds-headline-06'>{t("TS_core:Id")}</span><span>{csd['definitionId']}</span>
          </div>
        </div>
        <div className='label sdds-headline-07 sdds-u-mt3'>{t("TS_core:CreatedAt")}</div>
        <div className='sdds-u-mt1'><DateTimeSeperator inputDateTime={csd.creationTime} /></div>

        <div className='label sdds-headline-07 sdds-u-mt3'>{t("TS_core:LogEntryId")}</div>
        <div className='sdds-u-mt1'>{csd.logEntryId}</div>

        <div className='label sdds-headline-07 sdds-u-mt3'>{t("TS_core:CorrelationId")}</div>
        <div className='sdds-u-mt1'>{csd.correlationIds.join(", ")}</div>

        <div className='label sdds-headline-07 sdds-u-mt3'>{t("TS_core:ProductEntity")}</div>
        <div className='sdds-u-mt1'>
          {
            csd.productEntity.map((entry: CsdAttribute) => {
              return <>
                <div className='sdds-headline-06'>{entry.type}</div>
                <div className='sdds-body-02 sdds-u-pt1'>{entry.value}</div>
              </>
            })

          }
        </div>

        <div className='label sdds-headline-07 sdds-u-mt3'>{t("TS_core:User")}</div>
        <div className='sdds-u-mt1'>{csd.user.length > 0 ? csd.user.join(", ") : t("TS_core:None")}</div>
      </div>
      <div className='definition-panel'>
        {isCsdDefLoading && <Spinner size='sm' />}
        {(!csdDefinition || !csdDefinition.systemName) && !isCsdDefLoading && <div>{t("TS_core:NoCsdDefinitionFound")}</div>}
        <CsdDefinition csd={csd} csdDefinition={csdDefinition} selectedVehicle={selectedVehicle} />
      </div>
    </TabsComponent>
  </div>
}

export default CsdDetails