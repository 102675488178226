import React, { memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import BatchSearchExcelExportButton from "../BatchSearchExcelExportButton";
import { getColumnsConfig } from "../../helpers/batchSearchService";
import ExportIcon from "../../icons/ExportIcon";
import { DataTable, DataRow, DataCell, Pagination } from '@cd/sdds-common-components-react'
import {RedirectSvg} from '@cd/sdds-common-components-react/lib/esm/components/assets'
import { utcFormatFromString } from "../../helpers/timeFormatter";
import PositionData from "../CommunicationHistory/Position";
import { Link } from "react-router-dom";
interface BatchSearchDataTablePropTypes {
  data: Array<any>
  onSortData?: (columnId: string, sortDirection: string) => void
  onSearch?: (searchString: string) => void 
}

const BatchSearchDataTable = ({ data, onSortData, onSearch }: BatchSearchDataTablePropTypes) => {
  const { t } = useTranslation();
  const columnConfig = getColumnsConfig(t);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);

  useEffect(() => {
    setCurrentPageIndex(1);
  }, [data])

  const handlePaginationData = useMemo(() => {
    const firstPageIndex = (currentPageIndex - 1) * 20;
    const lastPageIndex = firstPageIndex + 20;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPageIndex, data]);


  return <>
  <DataTable
    title={`${t("TS_core:EquipmentFound")} (${data.length})`}
    columns={columnConfig.columns}
    onSearchChange={onSearch}
    onSort={onSortData}
    actionBar={<BatchSearchExcelExportButton slot="sdds-table__actionbar" data={data} disabled={!data || data.length === 0}>
      <span>{t("TS_core:ExportToExcel")}</span><span className='sdds-btn-icon'><ExportIcon /></span>
    </BatchSearchExcelExportButton>} >
    <>
      {data && handlePaginationData.map((row, index) => {
        return <DataRow key={`row-${index}`}>
          {columnConfig.columns.map((col: ColumnConfigType, colIndex: number) => {
            switch (col.columnid) {
              case 'vin':
                return <DataCell key={`col-index-${colIndex}`}>
                  <div>{row.vin}
                    <div className="last-line hide sdds-detail-03">
                      <div className='redirect-labels'> <Link className={`redirect redirect-link`} to={`https://equipment${process.env.REACT_APP_CSTT_DOMAIN}/communication/` + row.externalEquipmentReference} target="_blank" rel="noopener noreferrer"><RedirectSvg />{t('TS_core:Equipment')}</Link></div>
                      <div className='redirect-labels'><Link className={`redirect redirect-link`} to={`https://servicetroubleshooting${process.env.REACT_APP_CSTT_DOMAIN}/service-troubleshooting/` + row.externalEquipmentReference} target="_blank" rel="noopener noreferrer"><RedirectSvg /> {t("TS_core:GuidedTroubleshooting")}</Link></div>
                    </div>
                  </div>
                </DataCell>
              case 'customerSubscriptions':
                return <DataCell key={`col-index-${colIndex}`}>
                  {row.customerSubscriptions && row.customerSubscriptions.map((customer: CustomerSubscriptionSubType, index: number) => {
                    return <span key={`customer-sub-${index}`}>{customer.customerName}
                      <ul>
                        {customer.subscriptions && customer.subscriptions.map((subscription,index) => {
                          return <li key={`sub-${index}`}>{subscription}</li>
                        })}
                      </ul>
                    </span>
                  })}
                </DataCell>;
              case 'latestPosition':
                return <DataCell key={`col-index-${colIndex}`}>
                  {row.latestPosition.lat && row.latestPosition.long && row.latestPosition.timePosition ?
                    <>{utcFormatFromString(row.latestPosition.timePosition)}<br /> 
                       <PositionData latitude={row.latestPosition.lat}  longitude={row.latestPosition.long}/></>
                    : <>{" - "}</>
                  }
                </DataCell>;
              default:
                return <DataCell key={`col-index-${colIndex}`}>{row[col.columnid]}</DataCell>
            };
          })}
        </DataRow>;
      })}
    </>
  </DataTable>
   <Pagination currentPage={currentPageIndex} totalCount={data.length} pageSize={20} onPageChange={(page: any) => setCurrentPageIndex(page)} />
</>
  
}
export default memo(BatchSearchDataTable);