import { Header, NavLink, NoRoleDisclaimer } from "@cd/sdds-common-components-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Outlet } from "react-router-dom"
import { RBACWrapper } from "react-simple-rbac"
import { useAppSelector } from "../redux/store"
import { UserContext } from "../appContext"
import EquipmentTour from '../components/EquipmentTour';

const Index = () => {

  const { t } = useTranslation();
  const loggedInProfileInfo = useContext<ProfileInformationType>(UserContext);
  const { selectedCustomer } = useAppSelector<SearchState>(state => state.universalSearch)
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [searchStatus, setSearchStatus] = useState<boolean>(false);
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [searchResult, setSearchResult] = useState<string>('');
  const [scaniaSignedAndRead, setScaniaSignedAndRead] = useState<boolean>(true);
  const currentApp = 'Equipment';


  useEffect(() => {
    if (selectedCustomer && (!selectedCustomer?.scaniaLegalDocumentationSigned || !selectedCustomer?.scaniaRead)) {
      setScaniaSignedAndRead(false);
    } else {
      if ((selectedCustomer === undefined || selectedCustomer === null) && (loggedInProfileInfo?.role === 'Admin' || loggedInProfileInfo?.role === 'GTS')) {
        setScaniaSignedAndRead(true);
      } else if ((selectedCustomer === undefined || selectedCustomer === null)) {
        setScaniaSignedAndRead(false);
      }
      else {
        setScaniaSignedAndRead(true);
      }
    }
  }, [selectedCustomer, selectedCustomer?.scaniaLegalDocumentationSigned, selectedCustomer?.scaniaRead, loggedInProfileInfo?.role,]);

  return (
    <>
    {(!loggedInProfileInfo.role || loggedInProfileInfo.role === null) ? (
          <>
          <NoRoleDisclaimer />
          </>
        ) : 
       (
        <>
        <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"]}>
      <Header
        profileInfo={loggedInProfileInfo}
        currentApp={currentApp}
        setSearchComplete={setSearchStatus}
        setSearchStatus={setSearchResult}
        equipmentRedirectPath={'communication'}
        tours={[<EquipmentTour loggedInProfileInfo={loggedInProfileInfo} />]}
      >
                   

        <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited"]}>
          <NavLink path="/communication" text={t("TS_core:Communication")} idd="communication_Tour_Id" />
        </RBACWrapper>
        <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "FQ"]}>
          <NavLink path="/connected-services-deviations" text={t("TS_core:ConnectedServicesDeviations")} isScaniaRead={!scaniaSignedAndRead} uniqueId={"ConnectedServicesDeviationsSignedAndRead"} idd="connectedServicesDeviations_Tour_Id" />
        </RBACWrapper>
        <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "FQ"]}>
          <NavLink path="/equipment-batch-search" text={t('TS_core:EquipmentBatchSearch')} idd={"EquipmentSatchSearch_Tout_id"} />
        </RBACWrapper>
          <NavLink path="/vehicle-rtc-history" text={t("TS_core:VehicleRtcHistory")} idd={"EquipmentRtcHistory_Tour_id"} />
      </Header>
      </RBACWrapper>
      <Outlet />
         </>
       ) 
      } 
    </>

  )
}

export default Index