import React, { memo, useEffect, useState} from 'react'
import { Clock } from '@cd/sdds-common-components-react/lib/esm/components/assets';
import { calculationTimeDelay } from '../../../helpers/delayCalculator';
import { useTranslation } from 'react-i18next';

const TimeDelayIndicator =({ preTime, postTime }: any) => {
    const [color, setColor] = useState('');
    const [timeDelay, setTimeDelay] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        const duration = calculationTimeDelay(preTime, postTime );
        if(duration.timeDelaySecond < 60) {
           setColor('#1DAB8B');
       
        } else if (duration.timeDelaySecond < 1800) {
            setColor('#F1C21B'); 
        } else {
            setColor('#FF2340');
        }
        setTimeDelay(duration.timeDelay);
         
    },[preTime, postTime]);
   
    return <>  
        <span className='timeSvg' title={t("TS_core:DelayWithColon") +" " + timeDelay.trim()} style={{}}>
            <Clock fillColor={color} />
        </span>
        
    </>;
    }

    
  export default memo(TimeDelayIndicator)