import { createSlice } from '@reduxjs/toolkit'
import {
  fetchCscEquipment, fetchEquipmentAggregate, fetchEquipmentAggregateDetail, putActivateEquipment,
  putOverideCscCode, fetchCscValueList, deleteOverideCscCode, fetchOverideCscCode
} from '../../adapters/ServiceConfigurationAdapter';
import { find } from 'lodash';

const getInitialState = (): CscCodeStateType => {
  const State: CscCodeStateType = {
    isLoading: false,
    hasError: false,
    errorMessage: "",
    cscData: [],
    equipmentAggregate: {},
    equipmentaggregatedetail: {},
    handle: "",
    isAggregateLoading: false,
    isActiveEquipmentLoading: false,
    isCscValueUpdating: false,
    updateCscCodeResponse: {},
    isCscValueLoading: false,
    stepSliderConfig: null,

    isOverideApiLoading: false,
    csc5Value: undefined,
    csc5OverrideValue: undefined,
    overrideCscData: [],
    overrideApiError: "",
    isCsc5Overriden: false,
    searchEquipmentReference:''
    
  };
  return State;
}

// Slice
const slice = createSlice({
  name: 'cscEquipment',
  initialState: getInitialState(),
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    updateCscData: (state, action) => {
      state.cscData = action.payload;
      const csc5 = find(state.cscData, { code: 5 })
      if (csc5 !== null) {
        state.csc5Value = csc5?.numValue;
      }
    },
    updateAggregateData: (state, action) => {
      state.equipmentAggregate = action.payload;
    },
    updateAggregateDetailData: (state, action) => {
      state.equipmentaggregatedetail = action.payload;
    },
    activateEquipmentDetails: (state, action) => {
      state.handle = action.payload;
    },
    setIsAggregateLoading: (state, action) => {
      state.isAggregateLoading = action.payload
    },
    setActiveEquipmentLoading: (state, action) => {
      state.isActiveEquipmentLoading = action.payload
    },
    setIsCscvalueUpdating: (state, action) => {
      state.isCscValueUpdating = action.payload
    },
    updateOverideCscCode: (state, action) => {
      state.updateCscCodeResponse = action.payload
    },
    getCscValueList: (state, action) => {
      state.stepSliderConfig = action.payload
    },
    setIsCscValueLoading: (state, action) => {
      state.isCscValueLoading = action.payload
    },
    updateError: (state, action) => {
      state.hasError = action.payload !== '';
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    updateIsOverideApiLoading: (state, action) => {
      state.isOverideApiLoading = action.payload
    },
    updateCsc5OverrideValue: (state, action) => {
      state.csc5OverrideValue = action.payload
    },
    updateOverrideCscData: (state, action) => {
      state.overrideCscData = action.payload
    },
    updateOverrideApiError: (state, action) => {
      state.overrideApiError = action.payload;
    },
    updateIsCsc5Overriden: (state, action) => {
      state.isCsc5Overriden = action.payload;
    },
    setSearchEquipmentReference: (state, action) => {
      state.searchEquipmentReference = action.payload
    },
  },
});
export default slice.reducer

// Actions
const { setIsLoading, updateCscData, updateAggregateData, updateAggregateDetailData, activateEquipmentDetails, setActiveEquipmentLoading, setIsAggregateLoading, updateIsCsc5Overriden,
  updateOverideCscCode, setIsCscvalueUpdating, setIsCscValueLoading, getCscValueList, updateError, updateIsOverideApiLoading, updateCsc5OverrideValue, updateOverrideCscData, setSearchEquipmentReference, updateOverrideApiError } = slice.actions

export const loadCscCodeData = (externalEquipmentReference: string, locale:string) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading(true));
    dispatch(setSearchEquipmentReference(externalEquipmentReference));
    let CscEquipmentResponseType = await fetchCscEquipment(externalEquipmentReference, locale);
    let cscEquipmentResponse = CscEquipmentResponseType.data.cscData;
    dispatch(updateCscData(cscEquipmentResponse));
    dispatch(setIsLoading(false));
  } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}

export const loadAggregateData = (externalEquipmentReference: string) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading(true));
    let equipmentAggregate = await fetchEquipmentAggregate(externalEquipmentReference);
    let equipmentAggregateResponse = equipmentAggregate.data;
    dispatch(updateAggregateData(equipmentAggregateResponse));
    dispatch(setIsLoading(false));
  } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}

export const loadAggregateDetailData = (externalEquipmentReference: string, locale:string) => async (dispatch: any) => {
  try {
    dispatch(setIsAggregateLoading(true));
    let equipmentAggregateDetail = await fetchEquipmentAggregateDetail(externalEquipmentReference, locale);
    let equipmentAggregateDetailResponse = equipmentAggregateDetail.data;
    dispatch(updateAggregateDetailData(equipmentAggregateDetailResponse));
    dispatch(setIsAggregateLoading(false));
  } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}


export const activateEquipmentData = (externalEquipmentReference: string) => async (dispatch: any) => {
  try {
    dispatch(setActiveEquipmentLoading(false));
    let activateEquipmentDetail = await putActivateEquipment(externalEquipmentReference);
    let equipmentAggregateDetailResponse = activateEquipmentDetail.data;
    dispatch(activateEquipmentDetails(equipmentAggregateDetailResponse));
    dispatch(setActiveEquipmentLoading(true));
  } catch (error: any) {
    dispatch(setActiveEquipmentLoading(false));
    dispatch(updateError(error.message));
    return console.error(error);
  }
}

export const updateOverideCscCodeData = (externalEquipmentReference: string, code: number, value: number, locale:string) => async (dispatch: any) => {
  try {
    dispatch(setIsCscvalueUpdating(true));
    dispatch(updateError(""));
    let overideCscCodeResponseType = await putOverideCscCode(externalEquipmentReference, code, value);
    let overideCscCodeResponse = overideCscCodeResponseType.data.cscData;
    dispatch(updateOverideCscCode(overideCscCodeResponse));
    dispatch(loadCscCodeData(externalEquipmentReference, locale));
    dispatch(setIsCscvalueUpdating(false));
  } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}

export const getCscValueDataList = (code: number) => async (dispatch: any) => {
  try {
    dispatch(setIsCscValueLoading(true));
    let cscValues = await fetchCscValueList(code);
    let cscValueListResponse = cscValues.data;
    const values = cscValueListResponse.map((item: any) => parseInt(item.value));
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    const stepValue = Math.round((maxValue - minValue) / (values.length - 1));
    const ticks = (values.length - 2);
    const sliderConfigDetails: StepSliderConfig = {
      minValue: minValue,
      maxValue: maxValue,
      step: stepValue,
      ticks: ticks
    }
    dispatch(getCscValueList(sliderConfigDetails));
    dispatch(setIsCscValueLoading(false));
  } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}

export const sortCSCDataList = (sortedCscData: CscCodeResponseType[]) => async (dispatch: any) => {
  try {
    dispatch(updateCscData(sortedCscData));
  } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}

export const loadOverrideData = (externalEquipmentReference: string) => async (dispatch: any, getState: any) => {
  try {
    dispatch(updateIsOverideApiLoading(true));
    const state = getState().cscEquipmentStore;
    let csc5OverrideValue = undefined;
    let cscOverrideResponse = await fetchOverideCscCode(externalEquipmentReference);
    const cscCode5 = find(cscOverrideResponse.data, { code: 5 });
    if (cscCode5) {
      csc5OverrideValue = cscCode5?.numValue;
      dispatch(updateIsCsc5Overriden(state.csc5Value === csc5OverrideValue))
    }
    dispatch(updateOverrideCscData(cscOverrideResponse.data));
    dispatch(updateCsc5OverrideValue(csc5OverrideValue));
    dispatch(updateIsOverideApiLoading(false));
  } catch (error: any) {
    dispatch(updateOverrideApiError(error.message));
    return console.error(error);
  }
}

export const addCscCode5 = (externalEquipmentReference: string, code: number, value: number, locale:string) => async (dispatch: any) => {
  try {
    dispatch(updateIsOverideApiLoading(true));
    dispatch(updateOverrideApiError(""));
    await putOverideCscCode(externalEquipmentReference, code, value);
    dispatch(loadCscCodeData(externalEquipmentReference, locale));
    dispatch(loadOverrideData(externalEquipmentReference));
    dispatch(updateIsOverideApiLoading(false));
  } catch (error: any) {
    dispatch(updateOverrideApiError(error.message));
    return console.error(error);
  }
}

export const deleteOverideCscCodeData = (externalEquipmentReference: string, code: number, locale:string) => async (dispatch: any) => {
  try {
    dispatch(updateIsOverideApiLoading(true));
    await deleteOverideCscCode(externalEquipmentReference, code);
    dispatch(updateCsc5OverrideValue(undefined));
    dispatch(loadCscCodeData(externalEquipmentReference, locale));
    dispatch(loadOverrideData(externalEquipmentReference));
  } catch (error: any) {
    dispatch(updateOverrideApiError(error.message));
    return console.error(error);
  }
}

export const resetOverrideAPIError = () => async (dispatch: any) => {
  dispatch(updateOverrideApiError(""));
}