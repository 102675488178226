import React, { memo } from 'react'
import { utcFormatFromStringDateTime } from "../../../helpers/timeFormatter";

const DateTimeSeperator = ({ inputDateTime }: any) => {
  const getDateTime = utcFormatFromStringDateTime(inputDateTime);
  return <>
    <span>{getDateTime.utcDate + ' '}</span>
    <span><b>{getDateTime.utcTime}</b></span>
  </>;
}
export default memo(DateTimeSeperator)