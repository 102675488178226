import { useTranslation } from "react-i18next";

type MessageFiltersAndFunctionsParams = {
    combinnedCommunicationData: any[],
    currentStatusList: CurrentStatusMsgResponseType,
    positionList: PositionMsgResponseType,
    functionIndicationList: FunctionIndicationMsgResponseType,
    messageFilterChecks: Map<string, boolean>,
    isLoading: boolean,
    onMessageFilterChangeInChild: (e: React.ChangeEvent<HTMLInputElement>) => void,
    handleLoadMoreMessages: () => void,
    children: React.ReactElement,
}

const MessageFiltersAndFunctions = ({ combinnedCommunicationData, currentStatusList, positionList, functionIndicationList, isLoading, onMessageFilterChangeInChild, handleLoadMoreMessages, messageFilterChecks, children }: MessageFiltersAndFunctionsParams) => {

    const { t } = useTranslation();
    return <div className="sdds-col-md-12 ">
        <div className='message-filters'>       
            <span className='sdds-u-mt0 sdds-u-mr2 sdds-headline-05'>{isLoading? t("TS_core:CalculatingAmountOfMessages") : t("TS_core:MessagesLoaded", [combinnedCommunicationData.length])}</span>
            <div className="sdds-u-mt0 flex-display">
                <div className="sdds-checkbox-item checkbox">
                    <input className="sdds-form-input" checked={messageFilterChecks.get("CurrentStatus")} type="checkbox" name="cb-example" id="current-status-filter" onChange={onMessageFilterChangeInChild} value="CurrentStatus" />
                    <label className="sdds-form-label" htmlFor="current-status-filter">
                        <span title={`${t('TS_core:CurrentStatus')}`} className="marker current-status"></span>{currentStatusList?.data?.length > 0 ? currentStatusList.data.length : '0'}
                    </label>
                </div>
                <div className="sdds-checkbox-item checkbox">
                    <input className="sdds-form-input" checked={messageFilterChecks.get("Position")} type="checkbox" name="cb-example" id="position-filter" onChange={onMessageFilterChangeInChild} value="Position" />
                    <label className="sdds-form-label" htmlFor="position-filter">
                        <span title={`${t("TS_core:Position")}`} className="marker position"></span>{positionList?.data?.length > 0 ? positionList.data.length : '0'}
                    </label>
                </div>
                <div className="sdds-checkbox-item checkbox">
                    <input className="sdds-form-input" checked={messageFilterChecks.get("FunctionIndication")} type="checkbox" name="cb-example" id="function-indication-filter" onChange={onMessageFilterChangeInChild} value="FunctionIndication" />
                    <label className="sdds-form-label" htmlFor="function-indication-filter">
                        <span title={`${t('TS_core:FunctionIndication')}`} className="marker function-indication"></span>{functionIndicationList?.data?.length > 0 ? functionIndicationList.data.length : '0'}
                    </label>
                </div>
            </div>
            <button type="button" className="sdds-btn sdds-btn-md sdds-btn-secondary bttn" disabled={!(!isLoading && (positionList?.paginationtoken || functionIndicationList?.paginationtoken || currentStatusList?.paginationtoken))} onClick={handleLoadMoreMessages}>{t("TS_temp:loadMore")}</button>
            {children && children}
        </div>
    </div>
}

export default MessageFiltersAndFunctions;