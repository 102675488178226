import React from 'react';
import { getColumnsConfig } from "../../../helpers/VehicleHistory";
import { DataCell, DataRow, DataTable,Spinner,TruckSvg } from '@cd/sdds-common-components-react';
import { useTranslation } from 'react-i18next';
import DateTimeSeperator from '../../CommunicationHistory/DateTimeSeperator';

interface VehicleHistoryPropTyps {
  vehicleHistoryDataList?: Array<VehicleHistory>
  onHandleRedirect: (searchInput: string) => void
  isVehicleHistoryLoading: boolean
}

const VehicleHistory = ({ vehicleHistoryDataList, isVehicleHistoryLoading, onHandleRedirect }: VehicleHistoryPropTyps) => {
  const { t } = useTranslation();
  const columnConfig = getColumnsConfig(t);

return <>
       <div className='history'>
        <h2><TruckSvg />{t("TS_core:VehicleHistory")}</h2>
        <DataTable
          title={''}
          columns={columnConfig.columns}
          >
      <>
        {vehicleHistoryDataList?.map((row: any) => {
          return <DataRow>
            {columnConfig.columns.map((col) => {
              switch (col.columnid) {
                case "link":
                  return (
                    <>
                        <DataCell>
                          <span className="button sdds-link sdds-link--no-underline" role="button" onKeyDown={() => { }} onClick={() => onHandleRedirect(row.gatewayReferenceId ? row.gatewayReferenceId : row.mobileId )}> {`${t("TS_core:ViewRTCHistory")}`}</span>
                        </DataCell>
                    </>
                  );
                  case "timeRegistered":
                    return (
                      <>
                      <DataCell><DateTimeSeperator inputDateTime={row[col.columnid]} /></DataCell>
                      </>
                    );

                   default:
                      return <DataCell>{row[col.columnid]}</DataCell>
              };
            })}
          </DataRow>;
        })
        }
      </>
      {(!vehicleHistoryDataList || vehicleHistoryDataList.length === 0) && isVehicleHistoryLoading &&
          <DataRow>
            <DataCell colSpan={3}><Spinner size="md" /></DataCell>
          </DataRow>
        }
        {(!vehicleHistoryDataList || vehicleHistoryDataList.length === 0) && !isVehicleHistoryLoading &&
          <DataRow>
            <DataCell colSpan={3}>{t("TS_core:NoDataFound")}</DataCell>
          </DataRow>
        }
    </DataTable>
    </div> 
  </>
}

export default VehicleHistory;