
import { TFunction } from "i18next";


let getColumnsConfig = (t: TFunction):CommHistoryGridConfigType => {
  return {
    headers: true,
    columns: [
      { columnid: 'messageType', title: '', sortable: false, type: '', convert: false, dataTitle: ''  }, 
      { columnid: 'gpsTimestamp', title: t("TS_core:PositionTime"), sortable: true, type: '', convert: false, removeable: true, dataTitle: t("TS_core:TimestampGnssOnboard") }, 
      { columnid: 'messageTimestamp', title: t("TS_core:MessageTime"), sortable: true, type: '', convert: false, dataTitle: t("TS_core:TimestampMessageCreatedOnboard"),defaultSortDirection:"desc" },
      { columnid: 'timeSaved', title: t("TS_core:MessageSaveTime"), sortable: true, type: '', convert: false, removeable: true, dataTitle: t("TS_core:TimestampMessageSavedOffboard") },
      { columnid: 'ignition', title: t("TS_core:Ignition"), sortable: false, type: '', convert: false, removeable: true, dataTitle: '' },
      { columnid: 'speed', title: t("TS_core:Speed"), sortable: false, type: 'm/s', convert: true, removeable: true, dataTitle: '' },
      //Removing Odometer default column
      //  { columnid: 'odometer', title: t("TS_core:TS_Odometer"), sortable: true, type: 'metre', convert: true, removeable: true },
      { columnid: 'odometerICL', title: t("TS_core:TS_Odometer")+"ICL", sortable: true, type: 'metre', convert: true, removeable: true, dataTitle: '' },
      { columnid: 'position', title: t("TS_core:Position"), sortable: false, type: '', convert: false, removeable: true, dataTitle: '' },
    ]
  };
}

export {
  getColumnsConfig
}