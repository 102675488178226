
const unitConvertor = (value: number, unit: string, unitType: string): string => {
  let convertedValue: string;
  if (unitType === "Metric") {
    //#region Speed
    if (unit === 'm/s') {
      convertedValue = value ? (Math.round((value * 3600 / 1000)) + ' km/h') : '';
    }
    else if (unit === 'mph') {
      convertedValue = value ? (Math.round((value * 1.609344)) + ' km/h') : '';
    }
    else if (unit === 'km/h') {
      convertedValue = Math.round(value) + ' km/h';
    }
    //#endregion
    //#region Distance
    else if (unit === 'metre') {
      convertedValue = value ? (Math.round((value / 1000)) + ' km') : '';
    }else if(unit === 'km'){
      convertedValue = value ? (Math.round(value) + ' km') : '';
    }
    //#endregion
    else {
      convertedValue = '';
    }
  }
  else if (unitType === "Imperial") {
    //#region Speed
    if (unit === 'm/s') {
      convertedValue = value ? (Math.round((value * 3600 / 1000 * 0.621371192)) + ' mph') : '';
    }
    else if (unit === 'km/h') {
      convertedValue = value ? Math.round(value / 0.621371192) + ' mph' : '';
    }
    //#endregion
    //#region Distance
    else if (unit === 'km') {
      convertedValue = value ? (Math.round((value * 0.621371192)) + ' miles') : '';
    }
    else if (unit === 'metre') {
      convertedValue = value ? (Math.round((value * 0.000621371192)) + ' miles') : '';
    }
    //#endregion
    else {
      convertedValue = '';
    }
  }
  else {
    convertedValue = '';
  }
  console.debug("Converting:", value, " From:", unit, "unit Type:", unitType," To:",convertedValue)

  return convertedValue;
}


export {
  unitConvertor
};




